import { observable, action } from 'mobx';
import { PromotePost } from '../domain/promote-post';
import api from '../api';

export class PromotePostStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';

  /*@observable postInput: Partial<PromotePost> = {
    //country: '',
    //district: '',
    name: '',
    number: '',
    //constitution: '',
    //language: '',
    //degree: '',
    //address: '',
    //address_id: '',
    //lodge_id: ''
  };*/

  @observable postData: any = [];

  constructor() {

  }


  setPostData = (postData: any) => {
    console.log('FASDFASDFASDF');
    console.log(postData);
    console.log('-------FASDFASDFASDF');
    this.postData = postData;
    /*if (postData.length > 0) {
      const post = postData[0];
      this.postInput.lodge_id = post.id;
      this.postInput.name = post.name;
      this.postInput.number = post.number;
      this.postInput.country = post.country;
    }
    else {
      // ...
    }*/
  };

  promotePost = async (miniToken) => {
    let dat = {
      miniToken: miniToken,
      content_id: (this.postData.post_type == 1) ? this.postData.meeting_id : this.postData.id,
      type: this.postData.post_type // 1 = meeting, 2 = social, 3 = emergency meeting, 4 = user post
    };
    console.log("DOUBLE CHECKING -> dat");
    console.log(dat);

    let result = await api.promotePost(dat);

    return result;
  };

}

export const promotePostStore = new PromotePostStore();
(window as any).promotePostStore = promotePostStore;
