import * as React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Alert
} from 'reactstrap';
import { TextInput } from '../inputs/text';
import { PolicyholderStore } from '../../stores/policyholder-store';
import { inject, observer } from 'mobx-react';

interface Props {
  policyholderStore?: PolicyholderStore;
}

export const ContactMeModal: React.SFC<Props> = inject('policyholderStore')(
  observer((props: Props) => {
    if (!props.policyholderStore) {
      return null;
    }

    const policyholderInput = props.policyholderStore.policyholderInput;
    const validationResult = props.policyholderStore.validationResult;

    return (
      <Modal
        className="shrink"
        isOpen={props.policyholderStore.callMeModalOpen}
      >
        <ModalHeader
          toggle={() => props.policyholderStore.toggleCallMeModal()}
        >        
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Col sm="12">
              <TextInput
                label="First name*"
                invalid={!!validationResult.first_name}
                validationMessage={validationResult.first_name}
                value={policyholderInput.first_name}
                onChange={e => {
                  policyholderInput.first_name = e.target.value;
                }}
                id="first_name"
              />
            </Col>
            <Col sm="12">
              <TextInput
                label="Cellphone*"
                invalid={!!validationResult.cellphone}
                validationMessage={validationResult.cellphone}
                value={policyholderInput.cellphone}
                onChange={e => {
                  policyholderInput.cellphone = e.target.value;
                }}
                id="cellphone"
              />
            </Col>
            <Col sm="12">
              <TextInput
                label="Email*"
                invalid={!!validationResult.email}
                validationMessage={validationResult.email}
                value={policyholderInput.email}
                onChange={e => {
                  policyholderInput.email = e.target.value;
                }}
                id="email"
              />
            </Col>
            <Col sm="12">
              {props.policyholderStore.callMeModalSuccess && (
                <Alert color="success">
                  Thank you for your request. A consultant will contact you
                  shortly.
                </Alert>
              )}
              {props.policyholderStore.callMeModalError && (
                <Alert color="warning">
                  Something went wrong, please try again.
                </Alert>
              )}
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.policyholderStore.toggleCallMeModal()}
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={() => {
              props.policyholderStore.requestCall();
            }}
          >
            Call me
          </Button>
        </ModalFooter>
      </Modal>
    );
  })
);
