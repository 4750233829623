// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { constructWAShareLink, districtTitleSwitch } from '../../helpers/utilities';

import { SearchStore } from '../../stores/search-store';
import { LodgeStore } from '../../stores/lodge-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LoginStore } from '../../stores/login-store';
import { ConfigurationStore } from '../../stores/configuration-store';

import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select'
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';

interface Props {
  nextStep: () => void;
  contributeStep: () => void;
  newLocationStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  searchStore?: SearchStore;
  lodgeStore?: LodgeStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('searchStore', 'lodgeStore', 'contributeStore', 'loginStore', 'configurationStore')
@observer
export class Search extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }



  render() {
    if (!this.props.searchStore) {
      return;
    }

    const searchStore = this.props.searchStore;

    const searchInput = searchStore.searchInput;
    const validationResult = searchStore.validationResult;

    const loginStore = this.props.loginStore;

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Search</u>
            </h4>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <h5>Criteria:</h5>
            <div>
              <Row>
                <Col>
                  <TextInput
                    id='name_number'
                    label='Lodge Name/Number*'
                    value={searchInput.search}
                    onChange={e => (searchInput.search = e.target.value)}
                    invalid={!!validationResult.search}
                    validationMessage={validationResult.search}
                  />
                </Col>
              </Row>
              <a href='#' onClick={searchStore.toggleExpand}>
                {searchStore.isExpanded ? 'Advanced Search Hide' : 'Advanced Search Show'}
              </a>
              {searchStore.isExpanded && (
                <div>
                  <br />
                  <Row>
                    <Col>
                      <FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type='checkbox'
                              name='radioToday'
                              checked={searchInput.today_only}
                              onClick={() => {
                                searchInput.today_only = !searchInput.today_only;
                                //this.props.applicationStore.validationResult = {};
                              }}
                            />{' '}
                            Meeting Today
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectInput
                        id='country'
                        label='Country'
                        invalid={!!validationResult.country}
                        validationMessage={validationResult.country}
                        value={searchInput.country}
                        onChange={e => {
                          searchStore.searchInput.country = e.target.value;
                          configurationStore.filterAddresses(
                            configurationStore.addressResults,
                            searchStore.searchInput.country,
                          );
                          configurationStore.filterConstitutions(
                            configurationStore.constitutionResults,
                            searchStore.searchInput.country,
                          );
                          searchInput.constitution = '';
                          searchInput.district = '';
                          configurationStore.filteredDistricts = [];
                          searchInput.address = '';
                        }}
                        options={configurationStore.countryResults}
                      />
                    </Col>
                    <Col>
                      <SelectInput
                        id='constitution'
                        label='Constitution'
                        invalid={!!validationResult.constitution}
                        validationMessage={validationResult.constitution}
                        value={searchInput.constitution}
                        onChange={e => {
                          //console.log(e.target.value.split('_', 1));
                          //console.log(e.target.value.split('_', 1)[0]);
                          searchInput.constitution = e.target.value;
                          configurationStore.filterDistricts(
                            configurationStore.districtResults,
                            searchStore.searchInput.country,
                            searchInput.constitution.split('_', 1)[0],
                          );
                          searchStore.searchInput.district = '';
                          searchStore.searchInput.address = '';
                        }}
                        options={configurationStore.filteredConstitutions}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SelectInput
                        id='district'
                        label={districtTitleSwitch(
                          searchInput.country,
                          searchInput.constitution.split('_', 1)[0],
                          configurationStore.filteredConstitutions,
                        )}
                        invalid={!!validationResult.district}
                        validationMessage={validationResult.district}
                        value={searchInput.district}
                        onChange={e => {
                          searchInput.district = e.target.value;
                        }}
                        options={configurationStore.filteredDistricts}
                      />
                    </Col>
                    <Col>
                      <SelectInput
                        id='degree'
                        label='Order'
                        invalid={!!validationResult.degree}
                        validationMessage={validationResult.degree}
                        value={searchInput.degree}
                        onChange={e => {
                          searchStore.searchInput.degree = e.target.value;
                        }}
                        options={configurationStore.degreeResults}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SearchableSelectInput
                        id='address'
                        label='Address'
                        invalid={!!validationResult.address}
                        validationMessage={validationResult.address}
                        value={searchInput.address}
                        onChange={selectedOption => {
                          if (selectedOption != null) {
                            searchStore.searchInput.address = selectedOption.value; // Set the address_id using the selected option's value property
                          }
                        }}
                        options={configurationStore.filteredAddresses}
                      />
                      <Button
                        className='small-font'
                        color=''
                        title='Add a missing address'
                        onClick={async () => {
                          this.props.newLocationStep();
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 14 14'
                          id='Location-Pin-3--Streamline-Core'
                          height='14'
                          width='14'
                        >
                          <desc>Location Pin 3 Streamline Icon: https://streamlinehq.com</desc>
                          <g id='location-pin-3--navigation-map-maps-pin-gps-location'>
                            <path
                              id='Union'
                              fill='#2859c5'
                              fill-rule='evenodd'
                              d='M2.007 9.506a0.5 0.5 0 0 0 -0.46 0.303L0.05 13.304A0.5 0.5 0 0 0 0.51 14h12.98a0.5 0.5 0 0 0 0.46 -0.697l-1.498 -3.495a0.5 0.5 0 0 0 -0.46 -0.303H2.007Z'
                              clip-rule='evenodd'
                              stroke-width='1'
                            ></path>
                            <path
                              id='Union_2'
                              fill='#8fbffa'
                              fill-rule='evenodd'
                              d='M7 0C4.378 0 2.194 2.183 2.194 4.804c0 0.856 0.294 1.7 0.705 2.464 0.413 0.768 0.959 1.484 1.501 2.094a15.626 15.626 0 0 0 1.521 1.49c0.214 0.181 0.401 0.328 0.546 0.433 0.07 0.05 0.138 0.096 0.195 0.13a1.038 1.038 0 0 0 0.174 0.083 0.54 0.54 0 0 0 0.405 -0.03 1.05 1.05 0 0 0 0.098 -0.054c0.058 -0.034 0.125 -0.08 0.196 -0.132 0.144 -0.105 0.331 -0.253 0.545 -0.435 0.428 -0.366 0.977 -0.884 1.52 -1.496 0.542 -0.612 1.088 -1.33 1.5 -2.095 0.411 -0.762 0.706 -1.603 0.706 -2.452C11.806 2.184 9.621 0 7 0Z'
                              clip-rule='evenodd'
                              stroke-width='1'
                            ></path>
                            <path
                              id='Vector'
                              fill='#2859c5'
                              d='M7 6.24a1.435 1.435 0 1 0 0 -2.87 1.435 1.435 0 0 0 0 2.87Z'
                              stroke-width='1'
                            ></path>
                          </g>
                        </svg>
                      </Button>
                      <span className='small-font-italic'>Missing address?</span>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
            <br />
            <Button
              color='primary'
              onClick={async () => {
                //const success = true;//await quoteStore.getQuote();
                //if (success) {
                //  this.props.nextStep()
                //}
                this.props.setLoadingTrue();

                this.props.lodgeStore.clearLodges();
                this.props.lodgeStore.searchString = searchInput.search;
                this.props.lodgeStore.country = searchInput.country;
                this.props.lodgeStore.district = searchInput.district.split('_', 1)[0];
                this.props.lodgeStore.constitution = searchInput.constitution.split('_', 1)[0];
                this.props.lodgeStore.degree = searchInput.degree;
                this.props.lodgeStore.today_only = searchInput.today_only;
                this.props.lodgeStore.address = searchInput.address;
                //this.props.lodgeStore.setSearchString(searchInput.search);
                this.props.lodgeStore.setLodges(loginStore);
                this.props.nextStep();

                this.props.setLoadingFalse();
              }}
            >
              Search
            </Button>
            {loginStore.isLoggedIn ? (
              <Button
                color='primary'
                onClick={async () => {
                  this.props.contributeStore.setEdits(loginStore);
                  this.props.contributeStep();
                }}
              >
                Contribute
              </Button>
            ) : (
              ''
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Search;
