import { observable, action } from 'mobx';
import { RaffleEntrants } from '../domain/raffle-entrants';
import api from '../api';

export class RaffleEntrantsStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  @observable users: any = [
    {value: 1, label: 'Adrian Tempelhoff'},
    {value: 2, label: 'Timothy Shepherd'},
    {value: 3, label: 'Stephan Trowbridge'},
    {value: 4, label: 'Craig Blignaut'},
    {value: 5, label: 'Klaus Nusse'}
  ];

  @observable entrant = ''; // to be an array of entrants!!!
  @observable entrants: any = [];

  @observable validationResult: { [k: string]: string } = {};

  constructor() {}

  getAllUsers = async () => {
    this.isLoading = true;

    try {
      const result = await api.getAllUsers();
      this.users = result;
      
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  addEntrant = () => {
    if (this.entrant != '') {
      this.entrants.push(this.entrant);
    }
  };

  @action
  removeEntrant = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < this.entrants.length) {
      this.entrants.splice(indexToRemove, 1);
    }
  };

}

export const raffleEntrantsStore = new RaffleEntrantsStore();
(window as any).raffleEntrantsStore = raffleEntrantsStore;
