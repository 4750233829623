import { observable, action } from 'mobx';
import { Lodge } from '../domain/lodge';
import api from '../api';

export class LodgeStore {
  @observable isLoading = false;

  @observable searchString?: string;
  @observable country?: string;
  @observable district?: string;
  @observable constitution?: string;
  @observable degree?: string;
  @observable today_only?: boolean;
  @observable address?: string;

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  constructor() {
    //this.getAllLodges();
  }

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  getAllLodges = async (miniToken) => {

    let dat = {
      miniToken: miniToken
    };

    /*
    this.validate();
    if (this.hasValidationError()) {
      return;
    }

    const quoteInCents = this.getApiQuoteInput();
    */
    this.isLoading = true;
    let result = [];
    try {
      /*if (this.searchString == '' && (this.constitution == '0' || this.constitution == ''))
      {
        result = await api.getAllLodges([]);
      }
      else if (this.searchString != '')
      {*/
        if(this.searchString == '')
          this.searchString = '%25';

        if(this.country == '')
          this.country = '0';

        if(this.district == '')
          this.district = '0';

        if(this.constitution == '')
          this.constitution = '0';

        if(this.degree == '')
          this.degree = '0';

        if(this.address == '')
          this.address = '0';

        result = await api.getAllLodgesSearch(this.searchString,
                                              this.country,
                                              this.district,
                                              this.constitution,
                                              this.degree,
                                              this.address,
                                              (this.today_only === false) ? '0' : '1',
                                              dat);
      /*}
      else if (this.searchString == '' && this.constitution != '0')
      {
          result = await api.getAllTheLodges(this.constitution, []);
      }*/

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setSearchString = (search: string) => {
    this.searchString = search;
    console.log('setting search string');
  };

  setLodges = (loginStore) => {
    this.getAllLodges(loginStore.miniToken);
    console.log('getting all lodges');
  };

  clearLodges = () => {
    this.lodgeResults = [];
    console.log('clearing lodge results');
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const lodgeStore = new LodgeStore();
(window as any).lodgeStore = lodgeStore;
