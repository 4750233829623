// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { AboutStore } from '../../stores/about-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  aboutStore?: AboutStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('aboutStore', 'contributeStore', 'loginStore',)
@observer
export class About extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.aboutStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const aboutStore = this.props.aboutStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>About</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
          <span className='small-font-italic'>
            <p>
              Established in 2021.
            </p>
            <p>
              The Freemason Travellers Guide a Freemason Alpha initiative aims to be a
              single open source repository of Masonic meetings and events world wide.
            </p>
            <p>
              Providing members, lodges, chapters, conclaves, tabernacles and the
              like with the tools to facilitate easier visitation, orchestration
              and administration.
            </p>
            <p>
              ...
            </p>
            <p>
              If you have any questions or should you wish to become involved as
              a contributor or integrate FTG into your system please
              contact <a href="mailto:info@freemason-alpha.com">info@freemason-alpha.com</a>
            </p>
          </span>
        </CardBody>
        </Card>

        <br />
        <span className="small-font-italic">Version 1.5.1</span>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default About;
