import { observable, action } from 'mobx';
import { Leaderboard } from '../domain/leaderboard';
import api from '../api';

export class LeaderboardStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable page?: number;

  @observable leaderboardResults: Partial<Leaderboard> = {
    meeting_type_attendance_numbers: [],
    constitution_order_attendance_numbers: [],
    lodges_attendance_numbers: [],
    users_attendance_numbers: [],
    users_visitation_numbers: [],
    users_login_numbers: [],
    users_suggestion_numbers: [],
    users_leaderboard: []
  };

  @observable editResults: any = [];

  constructor() {

  }

  getAllLeaderboards = async (loginStore) => {

    this.page = 0;
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getAllLeaderboards(dat);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllLeaderboards =======');
      console.log(result);
      this.leaderboardResults.meeting_type_attendance_numbers = result['meeting_type_attendance_numbers'];
      this.leaderboardResults.constitution_order_attendance_numbers = result['constitution_order_attendance_numbers'];
      this.leaderboardResults.lodges_attendance_numbers = result['lodges_attendance_numbers'];
      this.leaderboardResults.users_attendance_numbers = result['users_attendance_numbers'];
      this.leaderboardResults.users_visitation_numbers = result['users_visitation_numbers'];
      this.leaderboardResults.users_login_numbers = result['users_login_numbers'];
      this.leaderboardResults.users_suggestion_numbers = result['users_suggestion_numbers'];
      this.leaderboardResults.users_leaderboard = result['users_leaderboard'];
      console.log('============================');

      this.isLoading = false;
    }
  };

  setAllLeaderboards = (loginStore) => {
    this.getAllLeaderboards(loginStore);
    console.log('getting all leaderboards');
  };


}

export const leaderboardStore = new LeaderboardStore();
(window as any).leaderboardStore = leaderboardStore;
