import * as React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Alert
} from 'reactstrap';
import { TextInput } from '../inputs/text';
import { PolicyDocsStore } from '../../stores/policy-docs-store';
import { inject, observer } from 'mobx-react';

interface Props {
  policyDocsStore?: PolicyDocsStore;
}

export const TermsOfServiceModal: React.SFC<Props> = inject('policyDocsStore')(
  observer((props: Props) => {
    if (!props.policyDocsStore) {
      return null;
    }

    //const contributeModalInput = props.contributeStore.contributeModalInput;
    //const modalValidationResult = props.contributeStore.modalValidationResult;

    return (
      <Modal
        className=""
        isOpen={props.policyDocsStore.termsModalOpen}
      >
        <ModalHeader
          toggle={() => props.policyDocsStore.toggleTermsModal()}
        >
        Terms of Service
        </ModalHeader>
        <ModalBody>
          <div className="small-font"
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >

          <p>
          <span className="small-font-italic">Last updated: July 04, 2024</span>
          </p>
          <p>
          Welcome to a Freemason Alpha initiative The Freemason Travellers Guide a masonic social media platform and user-curated directory of lodges, socials, and meetings. These Terms of Service ("Terms") govern your use of our platform, so please read them carefully.
          </p>

          <p>
          <u>Acceptance of Terms</u>
          </p>
          <p>
          By accessing or using our platform, you agree to these Terms and our Privacy Policy. If you do not agree with any part of these Terms, you may not use our platform.
          </p>

          <p>
          <u>Use of the Platform</u>
          </p>
          <ol>
            <li>User Accounts: You must create an account to access certain features of our platform. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>

            <li>Content Submission: When you submit content (including reviews, ratings, comments, or listings), you retain ownership of your content. By submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content on our platform.</li>

            <li>Prohibited Activities: You agree not to:</li>
            <ul>
              <li>Use our platform for any illegal purpose or violate any laws.</li>
              <li>Impersonate any person or entity or falsely state or misrepresent your affiliation with a person or entity.</li>
              <li>Attempt to gain unauthorized access to any portion of our platform, or any systems or networks connected to our platform.</li>
              <li>Engage in any conduct that disrupts or interferes with the normal operation of our platform.</li>
            </ul>
          </ol>

          <p>
          <u>Content Guidelines</u>
          </p>
          <ol>
            <li>User-Generated Content: Our platform allows users to contribute content, including reviews, ratings, and listings. You agree that:</li>
            <ul>
              <li>You will only submit content that is accurate and truthful to the best of your knowledge.</li>
              <li>You will not submit content that is defamatory, obscene, abusive, or otherwise objectionable.</li>
              <li>You will not submit content that violates the rights of any third party, including intellectual property rights.</li>
            </ul>

            <li>Moderation: We reserve the right to moderate or remove any content that violates these Terms or our policies, without prior notice.</li>
          </ol>

          <p>
          <u>Intellectual Property</u>
          </p>
          <ol>
            <li>Ownership: Our platform and its content (excluding user-generated content) are owned by us or our licensors and are protected by intellectual property laws.</li>

            <li>License: Subject to these Terms, we grant you a limited, non-exclusive, non-transferable license to use our platform for your personal and non-commercial use.</li>
          </ol>

          <p>
          <u>Privacy</u>
          </p>
          <ol>
            <li>Data Collection: We collect and process personal information in accordance with our Privacy Policy. By using our platform, you consent to such data collection and processing.</li>

            <li>Cookies: We use cookies and similar technologies to enhance your experience on our platform. You can manage cookies through your browser settings.</li>
          </ol>

          <p>
          <u>Limitation of Liability</u>
          </p>
          <ol>
            <li>Disclaimer: To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>

            <li>Limitation of Liability: We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of our platform.</li>
          </ol>

          <p>
          <u>Termination</u>
          </p>
          <p>
          We may terminate or suspend your access to our platform without prior notice, for any reason, including if you breach these Terms.
          </p>

          <p>
          <u>Miscellaneous</u>
          </p>
          <ol>
            <li>Governing Law: These Terms shall be governed by and construed in accordance with the laws of South Africa, without regard to its conflict of law principles.</li>

            <li>Changes to Terms: We may update these Terms from time to time. We will notify you of any material changes by posting the new Terms on our platform. Your continued use of our platform after such changes constitutes your acceptance of the revised Terms.</li>

            <li>Contact Us: If you have any questions about these Terms, please contact us at info@freemason-alpha.com, or +27713712416.</li>
          </ol>

          <p>
          By using our platform, you agree to abide by these Terms of Service. Thank you for being part of our community!
          </p>


          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => props.policyDocsStore.toggleTermsModal()}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  })
);
