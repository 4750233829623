// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { UserAccountStore } from '../../stores/user-account-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';
import { QRCodeCanvas } from 'qrcode.react';
import { constructWAShareLink } from '../../helpers/utilities';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  profileStep: () => void;
  userAccountStore?: UserAccountStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('userAccountStore', 'contributeStore', 'loginStore',)
@observer
export class UserAccount extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.userAccountStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const userAccountStore = this.props.userAccountStore;//typeOfThankYou

    return (
      <div>
        <Row>
          <Col sm='12'>
            <h4>
              <u>Account</u>
            </h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
          <CardBody>
            <Row className=''>
              <Col>
                <span className='small-font'>
                  Your current balance is: <b>{loginStore.user_credits}</b> Credits
                </span>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className='post-option-active'>
          <CardBody className='top-buttons'>
            <Row className='row-justified-and-centered'>
              <Col>
                <div id='navigation_buttons'>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      this.props.profileStep();
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      height='14'
                      width='14'
                      id='Return-2--Streamline-Core'
                    >
                      <desc>Return 2 Streamline Icon: https://streamlinehq.com</desc>
                      <g id='return-2--arrow-return-enter-keyboard'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Back</span>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      userAccountStore.page = 1;
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Hand-Held--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Hand Held Streamline Icon: https://streamlinehq.com</desc>
                      <g id='hand-held--tablet-kindle-device-electronics-ipad-computer'>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M2.702 0.042C1.761 0.042 0.997 0.806 0.997 1.747v10.505c0 0.942 0.764 1.706 1.705 1.706h8.596c0.941 0 1.704 -0.764 1.704 -1.706V1.747c0 -0.941 -0.763 -1.705 -1.705 -1.705H2.702Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Union_2'
                          fill='#2859c5'
                          d='M0.997 12.252c0 0.942 0.764 1.706 1.705 1.706h8.596c0.941 0 1.704 -0.764 1.704 -1.706V10.5H0.997v1.752Z'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Union_3'
                          fill='#2859c5'
                          fill-rule='evenodd'
                          d='M3.862 2.886a0.75 0.75 0 0 1 0.75 -0.75h4.776a0.75 0.75 0 0 1 0 1.5H4.612a0.75 0.75 0 0 1 -0.75 -0.75Zm0 2.624a0.75 0.75 0 0 1 0.75 -0.75h4.776a0.75 0.75 0 0 1 0 1.5H4.612a0.75 0.75 0 0 1 -0.75 -0.75Zm0.75 1.874a0.75 0.75 0 1 0 0 1.5h2.866a0.75 0.75 0 1 0 0 -1.5H4.612Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Transactions</span>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      userAccountStore.page = 2;
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Piggy-Bank--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Piggy Bank Streamline Icon: https://streamlinehq.com</desc>
                      <g id='piggy-bank--institution-saving-bank-payment-finance'>
                        <path
                          id='Vector 2206 (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M0.575 2.055a0.625 0.625 0 0 0 -0.367 0.804c0.284 0.763 0.816 1.319 1.326 1.696a0.625 0.625 0 0 0 0.744 -1.005c-0.382 -0.282 -0.724 -0.659 -0.9 -1.128a0.625 0.625 0 0 0 -0.803 -0.367Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Union'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M10.302 0.386C9.53 0.351 8.058 0.704 7.349 2.081c-1.705 -0.058 -3.018 0.189 -4.015 0.667 -1.074 0.514 -1.753 1.284 -2.148 2.159 -0.913 2.025 -0.276 4.148 0.067 5.291l0.005 0.015c0.05 0.17 0.097 0.314 0.132 0.425l0.02 0.063 0.038 0.12 0 1.718a1 1 0 0 0 1 1h1.765a1 1 0 0 0 1 -1v-0.407h2.141v0.484a1 1 0 0 0 1 1h1.75a1 1 0 0 0 1 -1v-0.637c1.153 -0.451 2.124 -1.088 2.672 -2.267a0.5 0.5 0 0 0 0.047 -0.21V7.283a0.5 0.5 0 0 0 -0.183 -0.386l-0.695 -0.571c-0.015 -0.664 -0.101 -1.312 -0.385 -1.89 -0.287 -0.584 -0.756 -1.054 -1.456 -1.406V1.277c0 -0.375 -0.25 -0.866 -0.802 -0.89Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Vector 2207 (Stroke)'
                          fill='#2859c5'
                          fill-rule='evenodd'
                          d='M7.375 4.902c0 0.345 -0.28 0.625 -0.625 0.625l-1.854 0a0.625 0.625 0 1 1 0 -1.25l1.854 0c0.346 0 0.625 0.28 0.625 0.625Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Ellipse 1025'
                          fill='#2859c5'
                          d='M10.6 6.154a0.766 0.766 0 1 1 -1.532 0 0.766 0.766 0 0 1 1.531 0Z'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Top Up Options</span>
                  </Button>

                  <Button
                    className=''
                    color=''
                    onClick={async () => {
                      userAccountStore.page = 3;
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Coin-Share--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Coin Share Streamline Icon: https://streamlinehq.com</desc>
                      <g id='coin-share--payment-cash-money-finance-receive-give-coin-hand'>
                        <path
                          id='Vector'
                          fill='#2859c5'
                          d='M9.75 8.25a4 4 0 1 0 0 -8 4 4 0 0 0 0 8Z'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Vector (Stroke)'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='M9.75 2.625c0.345 0 0.625 0.28 0.625 0.625v2a0.625 0.625 0 1 1 -1.25 0v-2c0 -0.345 0.28 -0.625 0.625 -0.625Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Vector 1278'
                          fill='#8fbffa'
                          d='M1.96 6.85H0.117v4l1.828 1.828a4 4 0 0 0 2.829 1.172h5.843a1.5 1.5 0 0 0 0 -3h-3L4.788 8.022A4 4 0 0 0 1.96 6.85Z'
                          stroke-width='1'
                        ></path>
                        <path
                          id='Intersect'
                          fill='#2859c5'
                          fill-rule='evenodd'
                          d='M6.372 9.605a0.86 0.86 0 0 1 -1.326 1.082l-1.45 -1.45a0.625 0.625 0 0 0 -0.884 0.883l1.45 1.45a2.11 2.11 0 0 0 3.456 -0.72h-0.001L6.372 9.605Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Receive Credits</span>
                  </Button>

                  {/*<Button
                    className=''
                    color=''
                    onClick={async () => {
                      userAccountStore.page = 4;
                      //await profileStore.getUserWatchList(loginStore.miniToken);
                    }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 14 14'
                      id='Cog--Streamline-Core'
                      height='14'
                      width='14'
                    >
                      <desc>Cog Streamline Icon: https://streamlinehq.com</desc>
                      <g id='cog--work-loading-cog-gear-settings-machine'>
                        <path
                          id='Subtract'
                          fill='#8fbffa'
                          fill-rule='evenodd'
                          d='m5.557 0.69 -0.463 1.195 -1.594 0.904 -1.27 -0.194a1.077 1.077 0 0 0 -1.078 0.528l-0.43 0.754a1.077 1.077 0 0 0 0.086 1.217l0.807 1.001v1.81L0.83 8.906a1.077 1.077 0 0 0 -0.086 1.217l0.43 0.754a1.077 1.077 0 0 0 1.078 0.528l1.27 -0.194 1.573 0.904 0.463 1.196a1.076 1.076 0 0 0 1 0.689h0.905a1.076 1.076 0 0 0 1.002 -0.69l0.463 -1.195 1.572 -0.904 1.27 0.194a1.077 1.077 0 0 0 1.078 -0.528l0.43 -0.754a1.077 1.077 0 0 0 -0.086 -1.217l-0.807 -1.001v-1.81l0.786 -1.001a1.077 1.077 0 0 0 0.086 -1.217l-0.43 -0.754a1.076 1.076 0 0 0 -1.078 -0.528l-1.27 0.194 -1.573 -0.904L8.443 0.689A1.077 1.077 0 0 0 7.442 0h-0.884a1.077 1.077 0 0 0 -1.001 0.69ZM7 9.25a2.25 2.25 0 1 0 0 -4.5 2.25 2.25 0 0 0 0 4.5Z'
                          clip-rule='evenodd'
                          stroke-width='1'
                        ></path>
                      </g>
                    </svg>
                    <span className='feed-button-text'> Settings</span>
                  </Button>*/}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <hr />

        {userAccountStore.page == 1 ? (
          <div>
        <Card className='post-option-active'>
          <CardBody>
            <Row>
              <Col sm='12' className=''>
                <span>
                  <b>
                    <u>Transactions</u>
                  </b>
                </span>
              </Col>
            </Row>
            <div>
              {userAccountStore.userTransactions.map((transaction, index) => (
                <div>
                  <span className='x-small-font-italic'>{transaction.created_at}</span>
                  <br />
                  <span className='small-font-italic'>
                    <b>{transaction.amount}</b> Credits
                  </span>
                  <br />
                  <span className='small-font-italic'>{transaction.description}</span>
                  <hr />
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
          </div>
        ) :
          userAccountStore.page == 2 ? (
            <div className='toggle-email-setting-div-open'>
              <Row>
                <Col sm='4'>
                  <div className='pricing-top-label'>
                    <b>Starter Pack</b>
                  </div>
                  <Card className='post-option-active top-up-option'>
                    <CardBody>
                      <Row>
                        <Col>
                          <b>10 Credits</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className='x-small-font'>FOR</span>
                          <br />
                          <b>R33.00</b>
                          <br />
                          <br />
                        </Col>
                      </Row>

                      <Row>
                        <Col className='row-justified-and-centered-flex promote-button-col'>
                          <form
                            name='PayFastPayNowForm'
                            action='https://payment.payfast.io/eng/process'
                            method='post'
                            target='_blank'
                          >
                            <input required type='hidden' name='cmd' value='_paynow' />
                            <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815' />
                            <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg' />
                            <input required type='hidden' name='amount' value='33' />
                            <input required type='hidden' name='item_name' value='10 Credits' />
                            <input
                              type='hidden'
                              name='item_description'
                              value='10 credits within the Freemason Travellers Guide'
                            />
                            <table>
                              <tr>
                                <td>
                                  <input
                                    type='image'
                                    src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                    alt='Buy Now'
                                    title='Buy Now with Payfast'
                                  />
                                </td>
                              </tr>
                            </table>
                          </form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='4'>
                  <div className='pricing-top-label'>
                    <b>Most Loved</b>
                  </div>
                  <Card className='post-option-active top-up-option'>
                    <CardBody>
                      <Row>
                        <Col>
                          <b>100 Credits</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className='x-small-font'>FOR</span>
                          <br />
                          <b>R297.00</b> <s>R330.00</s>
                          <br />
                          <span className='small-font-italic'>(10% off)</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col className='row-justified-and-centered-flex promote-button-col'>
                          <form
                            name='PayFastPayNowForm'
                            action='https://payment.payfast.io/eng/process'
                            method='post'
                            target='_blank'
                          >
                            <input required type='hidden' name='cmd' value='_paynow' />
                            <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815' />
                            <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg' />
                            <input required type='hidden' name='amount' value='297' />
                            <input required type='hidden' name='item_name' value='100 Credits' />
                            <input
                              type='hidden'
                              name='item_description'
                              value='100 credits within the Freemason Travellers Guide'
                            />
                            <table>
                              <tr>
                                <td>
                                  <input
                                    type='image'
                                    src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                    alt='Buy Now'
                                    title='Buy Now with Payfast'
                                  />
                                </td>
                              </tr>
                            </table>
                          </form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm='4'>
                  <div className='pricing-top-label'>
                    <b>Best Value</b>
                  </div>
                  <Card className='post-option-active top-up-option'>
                    <CardBody>
                      <Row>
                        <Col>
                          <b>1000 Credits</b>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className='x-small-font'>FOR</span>
                          <br />
                          <b>R2640.00</b> <s>R3300.00</s>
                          <br />
                          <span className='small-font-italic'>(20% off)</span>
                        </Col>
                      </Row>

                      <Row>
                        <Col className='row-justified-and-centered-flex promote-button-col'>
                          <form
                            name='PayFastPayNowForm'
                            action='https://payment.payfast.io/eng/process'
                            method='post'
                            target='_blank'
                          >
                            <input required type='hidden' name='cmd' value='_paynow' />
                            <input required type='hidden' name='receiver' pattern='[0-9]' value='24874815' />
                            <input type='hidden' name='return_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='cancel_url' value='https://freemason-alpha.com/ftg' />
                            <input type='hidden' name='notify_url' value='https://freemason-alpha.com/ftg' />
                            <input required type='hidden' name='amount' value='2640' />
                            <input required type='hidden' name='item_name' value='1000 Credits' />
                            <input
                              type='hidden'
                              name='item_description'
                              value='1000 credits within the Freemason Travellers Guide'
                            />
                            <table>
                              <tr>
                                <td>
                                  <input
                                    type='image'
                                    src='https://my.payfast.io/images/buttons/BuyNow/Light-Small-BuyNow.png'
                                    alt='Buy Now'
                                    title='Buy Now with Payfast'
                                  />
                                </td>
                              </tr>
                            </table>
                          </form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
        ) : userAccountStore.page == 3 ? (
            <div>
              <div>
                <Row>
                  <Col sm='12'>
                    <h6>Your Receival Code:</h6>
                    <div>
                      <QRCodeCanvas value={'https://freemason-alpha.com/ftg?receivalcode=' + userAccountStore.receival_code} />
                      <Button
                        className='small-font'
                        color=''
                        title='Share your Receival QR code link'
                        onClick={async () => {
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 14 14'
                          id='Whatsapp--Streamline-Core'
                          height='14'
                          width='14'
                        >
                          <desc>Whatsapp Streamline Icon: https://streamlinehq.com</desc>
                          <g id='whatsapp'>
                            <path
                              id='Union'
                              fill='#8fbffa'
                              fill-rule='evenodd'
                              d='M13.627 7.003C13.627 3.396 10.614 0.377 7 0.379 3.39 0.381 0.38 3.394 0.38 7.003c0 1.213 0.336 2.412 0.968 3.449L0.41 12.95a0.5 0.5 0 0 0 0.557 0.668l3.28 -0.593A6.64 6.64 0 0 0 7 13.62c3.614 0.002 6.627 -3.008 6.627 -6.618Z'
                              clip-rule='evenodd'
                              stroke-width='1'
                            ></path>
                            <g id='Vector 596'>
                              <path
                                fill='#2859c5'
                                d='M7.356 10.314c1.091 0.7 2.232 0.098 2.969 -0.498 0.403 -0.326 0.39 -0.92 0.036 -1.298l-0.644 -0.685c-0.193 -0.206 -0.524 -0.207 -0.785 -0.1 -0.312 0.125 -0.68 0.163 -0.965 0.076 -0.946 -0.288 -1.343 -0.78 -1.664 -1.281 -0.256 -0.399 -0.134 -0.934 0.073 -1.333 0.131 -0.253 0.124 -0.587 -0.087 -0.78l-0.692 -0.631c-0.329 -0.3 -0.827 -0.352 -1.13 -0.025 -0.757 0.817 -1.264 2.16 -0.726 2.998 0.919 1.43 2.185 2.638 3.615 3.557Z'
                                stroke-width='1'
                              ></path>
                            </g>
                          </g>
                        </svg>
                      </Button>
                      <span className='small-font-italic'>
                      {'https://freemason-alpha.com/ftg?receivalcode=' + userAccountStore.receival_code}
                    </span>
                    </div>
                  </Col>
                </Row>
                <br />
                <p>Share this QR code with fellow members, for them to be able to send you credits.</p>
              </div>
            </div>
          ) : ''
        }

        <hr />
      </div>
    )
      ;
  }
}

export default UserAccount;
