import { observable, action } from 'mobx';
import {
  validateName,
  validateEmail,
  validateCellphone,
  validateIdNumber,
  validateDateOfBirth,
  validateAgeAgainstIDNumber
} from '../helpers/validation';
import { Contribute } from '../domain/contribute';
import api from '../api';

export class ContributeStore {
  @observable isLoading = false;

  @observable editResults: any = [];

  @observable page?: number;


  @observable contributeModalInput: { [k: string]: string } = {
    id_type: 'id'
    // TODO remove init
  };
  @observable modalValidationResult: { [k: string]: string } = {};

  @observable callMeModalOpen: boolean = false;
  @observable callMeModalSuccess: boolean = false;
  @observable callMeModalError: boolean = false;

  constructor() {

  }

  getAllSuggestedEdits = async (loginStore) => {
    this.page = 0;
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getAllSuggestedEdits(dat);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllSuggestedEdits =======');
      this.editResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  castVote = async (suggested_edit_id, up_down, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggested_edit_id: suggested_edit_id,
          vote: up_down
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

      const result = await api.getToken(null);
      console.log('======= getToken =======');
      console.log(result.token);
      console.log('============================');

      const result2 = await api.castVote(result.token, dat);
      console.log('======= castVote =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setEdits = (loginStore) => {
    this.getAllSuggestedEdits(loginStore);
    console.log('getting all suggest edits');
  };

  toggleCallMeModal = () => {
    this.callMeModalOpen = !this.callMeModalOpen;
    this.callMeModalError = false;
    this.callMeModalSuccess = false;
  };

  validateModalRequest = () => {
    this.modalValidationResult = {};
    this.modalValidationResult.first_name = !validateName(
      this.contributeModalInput.first_name
    )
      ? 'Enter a valid name.'
      : '';
    this.modalValidationResult.email = !validateEmail(this.contributeModalInput.email)
      ? 'Enter a valid email.'
      : '';

    this.modalValidationResult.cellphone = !validateCellphone(
      this.contributeModalInput.cellphone
    )
      ? 'Enter a valid cellphone number.'
      : '';
  };

}

export const contributeStore = new ContributeStore();
(window as any).contributeStore = contributeStore;
