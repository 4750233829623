// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { degreeFromId, degreeLogoFromId, orderLogoFromConstitutionAndOrder } from '../../helpers/utilities';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';

import { ConfigurationStore } from '../../stores/configuration-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  lodgeStore?: LodgeStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  addLodgeStore?: AddLodgeStore;
  addMeetingStore?: AddMeetingStore;
  addContactStore?: AddContactStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('lodgeStore',
'lodgeDetailsStore',
'addLodgeStore',
'addMeetingStore',
'addContactStore',
'loginStore',
'configurationStore')

@observer
export class Lodge extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  handleClearingOfAddLodge = async () => {
    this.props.addLodgeStore.clearLodgeInput();
    this.props.addMeetingStore.clearMeetingInput();
    this.props.addContactStore.clearContactInput();
    this.props.addLodgeStep();
    //await this.props.configurationStore.getAllAddresses();
  };

  render() {
    if (!this.props.lodgeStore) {
      return;
    }

    const lodgeDetailsStore = this.props.lodgeDetailsStore;

    const lodgeStore = this.props.lodgeStore;
    console.log("------- lodgeStore.lodgeResults -------");
    console.log(lodgeStore.lodgeResults);
    console.log("---------------------------------------");

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>{degreeFromId(lodgeStore.degree)}</h1>
          </Col>
        </Row>
        <br />
        <h5>{lodgeStore.isLoading ? 'Loading...' : 'Select for more details: (found: '+lodgeStore.lodgeResults.length+')'}</h5>
        <div>
        {lodgeStore.lodgeResults.map((lodge, index) => (
          <Card className='post-option-active pointing-cursor' onClick={async () => {
            //const success = true;//await quoteStore.getQuote();
            //if (success) {
            //  this.props.nextStep()
            //}
            //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
            this.props.setLoadingTrue();

            this.props.lodgeDetailsStore.clearLodgeInfo();
            await this.props.lodgeDetailsStore.setLodgeInfo(lodge.name, lodge.number, lodge.degree, loginStore.miniToken, loginStore.lodges);
            this.props.nextStep();

            this.props.setLoadingFalse();
          }}>
          {/*<Card className={`quote-option${
            lodge.active === 'Active'
              ? '-active'
              : ''
            }`} onClick={async () => {
              //const success = true;//await quoteStore.getQuote();
              //if (success) {
              //  this.props.nextStep()
              //}
              //this.props.lodgeDetailsStore.setSelectedLodgeName(lodge.name);
              this.props.setLoadingTrue();

              this.props.lodgeDetailsStore.clearLodgeInfo();
              await this.props.lodgeDetailsStore.setLodgeInfo(lodge.name, lodge.number, lodge.degree, loginStore.miniToken, loginStore.lodges);
              this.props.nextStep();

              this.props.setLoadingFalse();
            }}
            >*/}
          <CardBody>
            <img alt='logo' className="list-order-logo" src={`${orderLogoFromConstitutionAndOrder(lodge.constitution_id, lodge.degree)}`} height="40px" />
            <span>
              {lodge.name} No. {lodge.number} {lodge.short_constitution} {lodge.active === 'Active' ? '' : '(Inactive)'}
            </span>
          </CardBody>
          </Card>
        ))}
        </div>
        <br/>
        <div id='navigation_buttons' style={lodgeStore.isLoading ? { display: 'none' } : { display: 'block' }}>
          {loginStore.isLoggedIn ? (
            <Button color="primary" onClick={this.handleClearingOfAddLodge}>
              Add a Lodge
            </Button>
          ): ''}
          <Button color="" onClick={this.props.previousStep}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Back
          </Button>
        </div>
      </div>
    );
  }
}

export default Lodge;
