// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { AddMeetingStore } from '../../stores/add-meeting-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import { TimeInput } from '../inputs/time';

import { PolicyholderStore } from '../../stores/policyholder-store';

export const positions = [
  {
    value: '1',
    label: '1st'
  },
  {
    value: '2',
    label: '2nd'
  },
  {
    value: '3',
    label: '3rd'
  },
  {
    value: '4',
    label: '4th'
  },
  {
    value: '5',
    label: '5th'
  }
];

export const days = [
  {
    value: '1',
    label: 'Monday'
  },
  {
    value: '2',
    label: 'Tuesday'
  },
  {
    value: '3',
    label: 'Wednesday'
  },
  {
    value: '4',
    label: 'Thursday'
  },
  {
    value: '5',
    label: 'Friday'
  },
  {
    value: '6',
    label: 'Saturday'
  },
  {
    value: '7',
    label: 'Sunday'
  }
];

export const months = [
  {
    value: '1',
    label: 'January'
  },
  {
    value: '2',
    label: 'February'
  },
  {
    value: '3',
    label: 'March'
  },
  {
    value: '4',
    label: 'April'
  },
  {
    value: '5',
    label: 'May'
  },
  {
    value: '6',
    label: 'June'
  },
  {
    value: '7',
    label: 'July'
  },
  {
    value: '8',
    label: 'August'
  },
  {
    value: '9',
    label: 'September'
  },
  {
    value: '10',
    label: 'October'
  },
  {
    value: '11',
    label: 'November'
  },
  {
    value: '12',
    label: 'December'
  }
];

export const types = [
  {
    value: '1',
    label: 'Meeting'
  },
  {
    value: '2',
    label: 'Installation'
  }/*,
  {
    value: '3',
    label: 'Social'
  }*/
];

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  lodgesStep: () => void;
  addMeetingStore?: AddMeetingStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('addMeetingStore')
@observer
export class AddMeeting extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.addMeetingStore) {
      return;
    }

    const addMeetingStore = this.props.addMeetingStore;
    //console.log("------- addMeetingStore.lodgeResults -------");
    //console.log(addMeetingStore.lodgeResults);
    //console.log("---------------------------------------");

    const lodgeInput = addMeetingStore.lodgeInput;
    const validationResult = addMeetingStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h4><u>Add Meetings</u></h4>
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <span className="small-font-red" id='enough_meetings'>{validationResult['enough_meetings']}</span>
        {lodgeInput.meetings.map((meeting, index) => (
          <Row key={index}>
            <Col sm='12'>
              <Row>
                <Col sm="3">
                  <SelectInput
                    id='type'
                    label='Type'
                    invalid={!!validationResult[`meeting_${index + 1}.type`]}
                    validationMessage={validationResult[`meeting_${index + 1}.type`]}
                    value={meeting.type}
                    onChange={e => {
                      addMeetingStore.lodgeInput.meetings[index].type = e.target.value;
                    }}
                    options={types}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <TimeInput
                      label="Time"
                      invalid={!!validationResult[`meeting_${index + 1}.time`]}
                      validationMessage={validationResult[`meeting_${index + 1}.time`]}
                      value={meeting.time}
                      onChange={e => {
                        addMeetingStore.lodgeInput.meetings[index].time = e.target.value;
                      }}
                      id="time"
                    />
                </Col>
                <Col sm="3">
                  <SelectInput
                    id='position'
                    label='Occurrence'
                    invalid={!!validationResult[`meeting_${index + 1}.position`]}
                    validationMessage={validationResult[`meeting_${index + 1}.position`]}
                    value={meeting.position}
                    onChange={e => {
                      addMeetingStore.lodgeInput.meetings[index].position = e.target.value;
                    }}
                    options={positions}
                  />
                </Col>
                <Col sm="3">
                  <SelectInput
                    id='day'
                    label='Day'
                    invalid={!!validationResult[`meeting_${index + 1}.day`]}
                    validationMessage={validationResult[`meeting_${index + 1}.day`]}
                    value={meeting.day}
                    onChange={e => {
                      addMeetingStore.lodgeInput.meetings[index].day = e.target.value;
                    }}
                    options={days}
                  />
                </Col>
                <Col sm="3">
                  <SelectInput
                    id='month'
                    label='Month'
                    invalid={!!validationResult[`meeting_${index + 1}.month`]}
                    validationMessage={validationResult[`meeting_${index + 1}.month`]}
                    value={meeting.month}
                    onChange={e => {
                      addMeetingStore.lodgeInput.meetings[index].month = e.target.value;
                    }}
                    options={months}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button color="danger" onClick={() => addMeetingStore.removeMeeting(index)}>
                    Remove Meeting
                  </Button>
                </Col>
              </Row>
              <hr/>
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <Button color="primary" onClick={addMeetingStore.addMeeting}>
              Add Meeting
            </Button>
          </Col>
        </Row>

        </CardBody>
        </Card>

        <Button
        color="primary"
        onClick={() => {
          addMeetingStore.validate();
          const success = addMeetingStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            this.props.nextStep();
          }
        }}
        >
          Next
        </Button>
        <Button color="" onClick={this.props.previousStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default AddMeeting;
