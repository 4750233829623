import { observable, action } from 'mobx';
import { EmergencyMeeting } from '../domain/emergency-meeting';
import api from '../api';

export class EmergencyMeetingStore {
  @observable isLoading = false;

  @observable membershipClaimInput: Partial<EmergencyMeeting> = {
    lodge_id: 0,
    date: '',
    address: '',
    time: '',
    description: ''
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable lodgeResults: any = [];
  @observable selectedLodgeIndex?: number = -1;

  fieldsToFill: string[] = [];

  constructor() {
    //this.validate()
    this.validationResult = { };
    this.fieldsToFill = [];
  }

  validate = () => {
    const request = this.membershipClaimInput;
    this.validationResult = { };
    this.fieldsToFill = [];

    this.validationResult.date = (request.date == '')
      ? '* Required'
      : '';

    this.validationResult.time = (request.time == '')
      ? '* Required'
      : '';

    this.validationResult.description = (request.description == '')
      ? '* Required'
      : '';

    this.validationResult.address = (request.address == '')
      ? '* Required'
      : '';

    this.fieldsToFill = Object.keys(this.validationResult)

    console.log(this.validationResult);
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  getSelectedLodge = () => {
    return this.lodgeResults[this.selectedLodgeIndex];
  };

  getAllLodges = async () => {
    /*
    this.validate();
    if (this.hasValidationError()) {
      return;
    }

    const quoteInCents = this.getApiQuoteInput();
    */
    this.isLoading = true;
    try {
      const result = await api.getAllLodges([]);
      console.log('======= getAllLodges =======');
      this.lodgeResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setSelectedLodgeIndex = (index: number) => {
    this.selectedLodgeIndex = index;
    console.log('setting lodge index');
  };

  setLodges = () => {
    this.getAllLodges();
    console.log('getting all lodges');
  };

  addEmergencyMeeting = async (lodge_id, date, time, description, address, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggestion_type : '6', // 6 = emergency meeting
          action : '0', //0 = add, 1 = remove
          lodge_id: lodge_id,
          date: date,
          time: time,
          description: description,
          address: address
      };

      //console.log('======= dat =======');
      //console.log(dat);
      //console.log('============================');

      const result = await api.getToken(null);
      //console.log('======= getToken =======');
      //console.log(result.token);
      //console.log('============================');
      //const result2 = await api.claimMembership(result.token, dat);
      const result2 = await api.makeSuggestion('', dat);
      console.log(result2);
      console.log('======= makeSuggestion =======');
      console.log(result2.status);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  /*get percentageCompleted() {
    const fieldsFilled = Object.keys(this.quoteInput).filter(key => this.fieldsToFill.includes(key));
    const result = fieldsFilled.length/this.fieldsToFill.length;
    return Math.round(result * 100)
  }*/

  get selectedLodge () {
    return this.lodgeResults[this.selectedLodgeIndex || 0];
  }
}

export const emergencyMeetingStore = new EmergencyMeetingStore();
(window as any).emergencyMeetingStore = emergencyMeetingStore;
