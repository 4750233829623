// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import {
  degreeFromId,
  degreeLogoFromId,
  meetingStringToDate,
  meetingString,
  meetingStringToDateWithDescription,
  degreeFromIdNoCollective,
  getAddressFromConfigsById,
  getDescriptionFromConfigsById,
  getConstitutionFromConfigsById,
  getShortConstitutionFromConfigsById,
  getConstitutionWebsiteFromConfigsById,
  getDistrictFromConfigsById,
  getCountryFromConfigsById,
  getLanguageFromConfigsById
} from '../../helpers/utilities';

import { ContributeDetailsStore } from '../../stores/contribute-details-store';

import { LoginStore } from '../../stores/login-store';

import { ThankYouStore } from '../../stores/thank-you-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { ConfigurationStore } from '../../stores/configuration-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  addLodgeStep: () => void;
  thankYouStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  loginStep: () => void;
  contributeDetailsStore?: ContributeDetailsStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'contributeDetailsStore',
  'loginStore',
  'thankYouStore',
  'configurationStore')
@observer
export class ContributeDetails extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.contributeDetailsStore) {
      return;
    }

    if(!this.props.loginStore.isLoggedIn)
    {
      this.props.loginStep();
    }

    const contributeDetailsStore = this.props.contributeDetailsStore;
    //console.log("------- contributeStore.lodgeResults -------");
    //console.log(contributeStore.editResults);
    //console.log("---------------------------------------");

    const loginStore = this.props.loginStore;
    const thankYouStore = this.props.thankYouStore;

    const configurationStore = this.props.configurationStore;

    try {
      thankYouStore.typeOfThankYou = 'vote';
    } catch (error) {
      // Handle the error here or log it
      console.error("Error updating state:", error);
    }

    return (
      <div>
        <Row>
          <Col sm="12">
            {contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "3"
              ? <h4><u>{contributeDetailsStore.editInfo.lodge.name} No. {contributeDetailsStore.editInfo.lodge.number} {contributeDetailsStore.editInfo.lodge.short_constitution} (New)</u></h4>
              : contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "4"
              ? <h4><u>{contributeDetailsStore.editInfo.lodge.name} No. {contributeDetailsStore.editInfo.lodge.number} {contributeDetailsStore.editInfo.lodge.short_constitution} (Edit)</u></h4>
              : contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "5"
              ? <h4><u>Membership Claim</u></h4>
              : contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "6"
              ? <h4><u>Emergency Meeting</u></h4>
              : contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "7"
              ? <h4><u>New Location</u></h4>
              : contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "8"
              ? <h4><u>Event</u></h4>
              : <h4><u>UNKNOWN</u></h4>
            }
          </Col>
        </Row>

        <Card className='post-option-active'>
        <CardBody>
        <h5>{contributeDetailsStore.isLoading ? 'Loading...' : 'Details:'}</h5>

        {contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "4"
          ?
        <div>
        <Row>
          <Col sm="6">
          <span className='lodge-details-heading'>Contact:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.contact != contributeDetailsStore.lodgeInfo[0].contact
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].contact}</s> {contributeDetailsStore.editInfo.lodge.contact}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.contact}</span>
          }
          </Col>
          <Col sm="6">
          <span className='lodge-details-heading'>Contact Number:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.contact_number != contributeDetailsStore.lodgeInfo[0].contact_number
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].contact_number}</s> {contributeDetailsStore.editInfo.lodge.contact_number}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.contact_number}</span>
          }
          </Col>
        </Row>
        <Row>
          <Col sm="6">
          <span className='lodge-details-heading'>Constitution:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.constitution != contributeDetailsStore.lodgeInfo[0].constitution
            ? <span><s>{getConstitutionFromConfigsById(contributeDetailsStore.lodgeInfo[0].constitution, configurationStore.constitutionResults)}</s> {getConstitutionFromConfigsById(contributeDetailsStore.editInfo.lodge.constitution, configurationStore.constitutionResults)}</span>
            : <span>{getConstitutionFromConfigsById(contributeDetailsStore.editInfo.lodge.constitution, configurationStore.constitutionResults)}</span>
          }
          </Col>
          <Col sm="6">
          <span className='lodge-details-heading'>Order:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.degree != contributeDetailsStore.lodgeInfo[0].degree
            ? <span><s>{degreeFromIdNoCollective(contributeDetailsStore.lodgeInfo[0].degree)}</s> {degreeFromIdNoCollective(contributeDetailsStore.editInfo.lodge.degree)}</span>
            : <span>{degreeFromIdNoCollective(contributeDetailsStore.editInfo.lodge.degree)}</span>
          }
          </Col>
        </Row>
        <Row>
          <Col sm="6">
          <span className='lodge-details-heading'>Country:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.country != contributeDetailsStore.lodgeInfo[0].country
            ? <span><s>{getCountryFromConfigsById(contributeDetailsStore.lodgeInfo[0].country, configurationStore.countryResults)}</s> {getCountryFromConfigsById(contributeDetailsStore.editInfo.lodge.country, configurationStore.countryResults)}</span>
            : <span>{getCountryFromConfigsById(contributeDetailsStore.editInfo.lodge.country, configurationStore.countryResults)}</span>
          }
          </Col>
          <Col sm="6">
          <span className='lodge-details-heading'>District:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.district != contributeDetailsStore.lodgeInfo[0].district
            ? <span><s>{getDistrictFromConfigsById(contributeDetailsStore.lodgeInfo[0].district, configurationStore.districtResults)}</s> {getDistrictFromConfigsById(contributeDetailsStore.editInfo.lodge.district, configurationStore.districtResults)}</span>
            : <span>{getDistrictFromConfigsById(contributeDetailsStore.editInfo.lodge.district, configurationStore.districtResults)}</span>
          }
          </Col>
        </Row>
          <span className='lodge-details-heading'>Language:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.language != contributeDetailsStore.lodgeInfo[0].language
            ? <span><s>{getLanguageFromConfigsById(contributeDetailsStore.lodgeInfo[0].language, configurationStore.languageResults)}</s> {getLanguageFromConfigsById(contributeDetailsStore.editInfo.lodge.language, configurationStore.languageResults)}</span>
            : <span>{getLanguageFromConfigsById(contributeDetailsStore.editInfo.lodge.language, configurationStore.languageResults)}</span>
          }
          <br/>
          <span className='lodge-details-heading'>Address:</span>
          <br/>
          {
            getAddressFromConfigsById(contributeDetailsStore.editInfo.lodge.address, configurationStore.addressResults)
            != getAddressFromConfigsById(contributeDetailsStore.lodgeInfo[0].address_id, configurationStore.addressResults)
            ? <span><s>{getAddressFromConfigsById(contributeDetailsStore.lodgeInfo[0].address_id, configurationStore.addressResults)}</s>
                <br/>{getAddressFromConfigsById(contributeDetailsStore.editInfo.lodge.address, configurationStore.addressResults)}</span>
            : <span>{getAddressFromConfigsById(contributeDetailsStore.editInfo.lodge.address, configurationStore.addressResults)}</span>
          }
          <br/>
          <span className='lodge-details-heading'>Website:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.website != contributeDetailsStore.lodgeInfo[0].website
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].website}</s> {contributeDetailsStore.editInfo.lodge.website}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.website}</span>
          }
          <br/>
          <span className='lodge-details-heading'>Email:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.email != contributeDetailsStore.lodgeInfo[0].email
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].email}</s> {contributeDetailsStore.editInfo.lodge.email}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.email}</span>
          }
          <br/>
          <span className='lodge-details-heading'>Meetings:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.meeting_time + ' ' + contributeDetailsStore.editInfo.lodge.meeting_date
            != contributeDetailsStore.lodgeInfo[0].meeting_time + ' ' + contributeDetailsStore.lodgeInfo[0].meeting_date
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].meeting_time + ' ' + contributeDetailsStore.lodgeInfo[0].meeting_date}</s> {contributeDetailsStore.editInfo.lodge.meeting_time + ' ' + contributeDetailsStore.editInfo.lodge.meeting_date}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.meeting_time + ' ' + contributeDetailsStore.editInfo.lodge.meeting_date}</span>
          }
          <br/>
          <span className='lodge-details-heading'>Installation:</span>
          <br/>
          {
            contributeDetailsStore.editInfo.lodge.installation_time + ' ' + contributeDetailsStore.editInfo.lodge.installation_date
            != contributeDetailsStore.lodgeInfo[0].installation_time + ' ' + contributeDetailsStore.lodgeInfo[0].installation_date
            ? <span><s>{contributeDetailsStore.lodgeInfo[0].installation_time + ' ' + contributeDetailsStore.lodgeInfo[0].installation_date}</s> {contributeDetailsStore.editInfo.lodge.installation_time + ' ' + contributeDetailsStore.editInfo.lodge.installation_date}</span>
            : <span>{contributeDetailsStore.editInfo.lodge.installation_time + ' ' + contributeDetailsStore.editInfo.lodge.installation_date}</span>
          }
          <br/><br/>
          <h5>Meetings: (Currently listed)</h5>
          {contributeDetailsStore.lodgeMeetingsTwo.map((meeting, index) => (
            <div key={index}>
              {meetingStringToDateWithDescription(meeting.position,meeting.day,meeting.month, meeting.type, meeting.description, meeting.description_extra, configurationStore.descriptionResults)}
            </div>
          ))}
          <br/>
          <h5>Meetings: (Suggested Changes)</h5>
          {contributeDetailsStore.editInfo.lodge.meetings.map((meeting, index) => (
            <div key={index}>
              {meetingStringToDateWithDescription(meeting.week,meeting.day,meeting.month, meeting.type, meeting.description, meeting.description_extra, configurationStore.descriptionResults)}
            </div>
          ))}
        </div>

          :
            contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "3"
            ?
          <div>
          <Row>
            <Col sm="6">
            <span className='lodge-details-heading'>Contact:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.contact}</span>
            }
            </Col>
            <Col sm="6">
            <span className='lodge-details-heading'>Contact Number:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.contact_number}</span>
            }
            </Col>
          </Row>
          <Row>
            <Col sm="6">
            <span className='lodge-details-heading'>Constitution:</span>
            <br/>
            {
              <span>{getConstitutionFromConfigsById(contributeDetailsStore.editInfo.lodge.constitution, configurationStore.constitutionResults)}</span>
            }
            </Col>
            <Col sm="6">
            <span className='lodge-details-heading'>Order:</span>
            <br/>
            {
              <span>{degreeFromIdNoCollective(contributeDetailsStore.editInfo.lodge.degree)}</span>
            }
            </Col>
          </Row>
          <Row>
            <Col sm="6">
            <span className='lodge-details-heading'>Country:</span>
            <br/>
            {
              <span>{getCountryFromConfigsById(contributeDetailsStore.editInfo.lodge.country, configurationStore.countryResults)}</span>
            }
            </Col>
            <Col sm="6">
            <span className='lodge-details-heading'>District:</span>
            <br/>
            {
              <span>{getDistrictFromConfigsById(contributeDetailsStore.editInfo.lodge.district, configurationStore.districtResults)}</span>
            }
            </Col>
          </Row>
            <span className='lodge-details-heading'>Language:</span>
            <br/>
            {getLanguageFromConfigsById(contributeDetailsStore.editInfo.lodge.language, configurationStore.languageResults)}
            <br/>
            <span className='lodge-details-heading'>Address:</span>
            <br/>
            {getAddressFromConfigsById(contributeDetailsStore.editInfo.lodge.address, configurationStore.addressResults)}
            <br/>
            <span className='lodge-details-heading'>Website:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.website}</span>
            }
            <br/>
            <span className='lodge-details-heading'>Email:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.email}</span>
            }
            <br/>
            <span className='lodge-details-heading'>Meetings:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.meeting_time + ' ' + contributeDetailsStore.editInfo.lodge.meeting_date}</span>
            }
            <br/>
            <span className='lodge-details-heading'>Installation:</span>
            <br/>
            {
              <span>{contributeDetailsStore.editInfo.lodge.installation_time + ' ' + contributeDetailsStore.editInfo.lodge.installation_date}</span>
            }
            <br/><br/>
            <h5>Meetings:</h5>
            {contributeDetailsStore.editInfo.lodge.meetings.map((meeting, index) => (
              <div key={index}>
                {meetingStringToDateWithDescription(meeting.week,meeting.day,meeting.month, meeting.type, meeting.description, meeting.description_extra, configurationStore.descriptionResults)}
              </div>
            ))}
          </div>
          :
            contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "5"
            ?
            <div>
              <span className='lodge-details-heading'>Member:</span>
              <br/>
              <span>{contributeDetailsStore.editInfo.user.name}</span>
              <br/>
              <span className='lodge-details-heading'>Proposer:</span>
              <br/>
              <span>{contributeDetailsStore.editInfo.user.proposer}</span>
              <br/>
              <span className='lodge-details-heading'>Seconder:</span>
              <br/>
              <span>{contributeDetailsStore.editInfo.user.seconder}</span>
              <br/>
              <span className='lodge-details-heading'>Date joined:</span>
              <br/>
              <span>{contributeDetailsStore.editInfo.user.joined}</span>
              <br/>
            </div>
            :
              contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "6"
              ?
              <div>
                <span className='lodge-details-heading'>Date:</span>
                <br/>
                {contributeDetailsStore.editInfo.emergency_meeting.date}
                <br/>
                <span className='lodge-details-heading'>Time:</span>
                <br/>
                {contributeDetailsStore.editInfo.emergency_meeting.time}
                <br/>
                <span className='lodge-details-heading'>Description:</span>
                <br/>
                {getDescriptionFromConfigsById(contributeDetailsStore.editInfo.emergency_meeting.description, configurationStore.descriptionResults)}
                <br/>
                <span className='lodge-details-heading'>Address:</span>
                <br/>
                {getAddressFromConfigsById(contributeDetailsStore.editInfo.emergency_meeting.address, configurationStore.addressResults)}
                <br/>
              </div>
              :
                contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "7"
                ?
                <div>
                  <span className='lodge-details-heading'>Name:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.name}
                  <br/>
                  <span className='lodge-details-heading'>Address:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.address}
                  <br/>
                  <span className='lodge-details-heading'>City:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.city}
                  <br/>
                  <span className='lodge-details-heading'>Region:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.region_name}
                  <br/>
                  <span className='lodge-details-heading'>Country:</span>
                  <br/>
                  {getCountryFromConfigsById(contributeDetailsStore.editInfo.location.country, configurationStore.countryResults)}
                  <br/>
                  <span className='lodge-details-heading'>Latitude:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.latitude}
                  <br/>
                  <span className='lodge-details-heading'>Longitude:</span>
                  <br/>
                  {contributeDetailsStore.editInfo.location.longitude}
                  <br/>
                  <a href={`http://maps.google.com/maps?q=${
                    contributeDetailsStore.editInfo.location.gps !== ''
                      ? contributeDetailsStore.editInfo.location.gps
                      : ''
                    }`} target="_blank">View on map</a>
                  <br/>
                </div>
                :
                  contributeDetailsStore.editInfo.suggested_edit.suggestion_type == "8"
                  ?
                  <div>
                    <Card className='post-option-active'>
                      <CardBody>
                        <b>{contributeDetailsStore.editInfo.social.title}</b>
                        <hr/>
                        <span className="small-font">
                        Date: {contributeDetailsStore.editInfo.social.date}
                        </span>
                        <br/>
                        <span className="small-font">
                        Time: {contributeDetailsStore.editInfo.social.time}
                        </span>
                        <br/>
                        <br/>
                        <div className="pre pre-code">{contributeDetailsStore.editInfo.social.description}</div>
                        <span className="small-font">
                        <i>Location: <a href={`http://maps.google.com/maps?q=${contributeDetailsStore.editInfo.social.gps}`} target="_blank">{contributeDetailsStore.editInfo.social.address}</a></i>
                        </span>
                        <Row>
                          <Col sm="12" className="post-restricted-height">
                            <img className='feed-wall-image' src={process.env.REACT_APP_API_URL + contributeDetailsStore.editInfo.social.image}/>
                          </Col>
                        </Row>
                        <br/>
                        <br/>
                        <span className="small-font">
                        <i>Contact: {contributeDetailsStore.editInfo.social.username}</i>
                        <br/>
                        <i>Tel: <a href={`tel:${contributeDetailsStore.editInfo.social.contact_number}`} target="_blank">{contributeDetailsStore.editInfo.social.contact_number}</a></i>
                        <br/>
                        <i>Email: <a href={`mailto:${contributeDetailsStore.editInfo.social.email}`} target="_blank">{contributeDetailsStore.editInfo.social.email}</a></i>
                        </span>
                      </CardBody>
                    </Card>
                  </div>
                :
                <div>UNKNOWN</div>
        }

        </CardBody>
        </Card>


        <hr/>
        <div id='navigation_buttons' style={contributeDetailsStore.isLoading ? { display: 'none' } : { display: 'block' }}>
          <Button
          disabled={contributeDetailsStore.editInfo.suggested_edit.user_vote_status === 'voted_up'}
          onClick={async () => {
            this.props.contributeDetailsStore.castVote(contributeDetailsStore.editInfo.suggested_edit.id, "up", loginStore);
            this.props.thankYouStep();
          }}
          style={{ background: 'none', border: 'none' }}
          >
            <img src="vote_up.png" alt="Vote Up" className={`vote-button${
              contributeDetailsStore.editInfo.suggested_edit.user_vote_status === 'voted_up'
                ? '-up'
                : ''
              }`}/>
          </Button>
          <Button
          disabled={contributeDetailsStore.editInfo.suggested_edit.user_vote_status === 'voted_down'}
          onClick={async () => {
            this.props.contributeDetailsStore.castVote(contributeDetailsStore.editInfo.suggested_edit.id, "down", loginStore);
            this.props.thankYouStep();
          }}
          style={{ background: 'none', border: 'none' }}
          >
            <img src="vote_down.png" alt="Vote Down" className={`vote-button${
              contributeDetailsStore.editInfo.suggested_edit.user_vote_status === 'voted_down'
                ? '-down'
                : ''
              }`}/>
          </Button>
        </div>
        <hr/>

        <br/>

        <div id='navigation_buttons' style={contributeDetailsStore.isLoading ? { display: 'none' } : { display: 'block' }}>
          <Button color="" onClick={this.props.previousStep}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            Back
          </Button>
          <br/><br/>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
        </div>

      </div>
    );
  }
}

export default ContributeDetails;
