import { observable, action } from 'mobx';
import { UserAccount } from '../domain/user-account';
import api from '../api';

export class UserAccountStore {
  @observable isLoading = false;
  @observable typeOfThankYou = '';
  @observable editResults: any = [];
  @observable page?: number;
  @observable receival_code?: string;

  @observable toggleOptionsDiv: { [k: number]: boolean } = {};

  @observable userTransactions: any = [];

  constructor() {

  }

  getUserTransactions = async (loginStore) => {
    this.isLoading = true;
    let result = [];
    try {
      let dat = {
        miniToken: loginStore.miniToken
      };
      result = await api.getUserTransactions(dat);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getUserTransactions =======');
      this.userTransactions = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  setUserTransactions = (loginStore) => {
    this.getUserTransactions(loginStore);
    console.log('getting all users approved transactions');
  };

}

export const userAccountStore = new UserAccountStore();
(window as any).userAccountStore = userAccountStore;
