import { observable, action } from 'mobx';
import { ContributeDetails } from '../domain/contribute-details';
import api from '../api';

export class ContributeDetailsStore {
  @observable isLoading = false;

  @observable editResults: any = [];

  @observable lodgeInput: Partial<ContributeDetails> = {
    name: '',
    number: '',
    degree: '',
    lodge_id: 0
  };

  @observable lodgeInfo: any = [];
  @observable lodgeMeetings: any = [];
  @observable lodgeMeetingsTwo: any = [];

  @observable editInfo: any = [];

  constructor() {

  }

  getLodgeInfo = async () => {
    this.isLoading = true;
    try {
      const result = await api.getLodgeInfoById(this.lodgeInput.lodge_id);
      console.log('======= getLodgeInfoById =======');
      this.lodgeInfo = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  getLodgeMeetings = async () => {
    this.isLoading = true;
    try {
      const result = await api.getLodgeMeetingsById(this.lodgeInput.lodge_id);
      console.log('======= getLodgeMeetingsById =======');
      this.lodgeMeetings = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  getLodgeMeetingsTwo = async () => {
    this.isLoading = true;
    try {
      const result = await api.getLodgeMeetingsTwoById(this.lodgeInput.lodge_id);
      console.log('======= getLodgeMeetingsTwoById =======');
      this.lodgeMeetingsTwo = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };


  getAllSuggestedEdits = async () => {

    this.isLoading = true;
    let result = [];
    try {

        result = await api.getAllSuggestedEdits([]);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {

      console.log('======= getAllSuggestedEdits =======');
      this.editResults = result;
      console.log(result);
      console.log('============================');

      this.isLoading = false;
    }
  };

  castVote = async (suggested_edit_id, up_down, loginStore) => {
    this.isLoading = true;

    try {
      let dat = {
          miniToken: loginStore.miniToken,
          suggested_edit_id: suggested_edit_id,
          vote: up_down
      };

      console.log('======= dat =======');
      console.log(dat);
      console.log('============================');

      const result = await api.getToken(null);
      console.log('======= getToken =======');
      console.log(result.token);
      console.log('============================');

      const result2 = await api.castVote(result.token, dat);
      console.log('======= castVote =======');
      //this.lodgeResults = result2;
      console.log(result2);
      console.log('============================');

      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };

  setEdits = () => {
    this.getAllSuggestedEdits();
    console.log('getting all suggest edits');
  };

  setLodgeInfo = async (name: string, number: string, degree: string, lodge_id, edit) => {
    this.lodgeInput.name = name;
    this.lodgeInput.number = number;
    this.lodgeInput.degree = degree;
    this.lodgeInput.lodge_id = lodge_id;
    this.editInfo = edit;
    if (this.lodgeInput.lodge_id != 0) {
      await this.getLodgeInfo();
      await this.getLodgeMeetings();
      await this.getLodgeMeetingsTwo();
      console.log('existing lodge info');
      console.log(this.lodgeInfo[0]);
    }
    console.log('setting lodge edit info');
    console.log(this.editInfo.lodge.email);

  };

  setMembershipInfo = async (edit) => {
    console.log(edit);
    this.lodgeInput.name = edit.suggested_edit.true_lodge_name;
    this.lodgeInput.number = edit.suggested_edit.true_lodge_name;
    //this.lodgeInput.degree = degree;
    this.lodgeInput.lodge_id = edit.lodge.lodge_id
    this.editInfo = edit;
    /*if (this.lodgeInput.lodge_id != 0) {
      await this.getLodgeInfo();
      await this.getLodgeMeetings();
      await this.getLodgeMeetingsTwo();
      console.log('existing lodge info');
      console.log(this.lodgeInfo[0]);
    }*/
    console.log('setting membership claim info');
    //console.log(this.editInfo.lodge.email);
  };

  setMeetingInfo = async (edit) => {
    console.log(edit);
    this.lodgeInput.name = edit.suggested_edit.true_lodge_name;
    this.lodgeInput.number = edit.suggested_edit.true_lodge_name;
    //this.lodgeInput.degree = degree;
    this.lodgeInput.lodge_id = edit.lodge.lodge_id
    this.editInfo = edit;
    /*if (this.lodgeInput.lodge_id != 0) {
      await this.getLodgeInfo();
      await this.getLodgeMeetings();
      await this.getLodgeMeetingsTwo();
      console.log('existing lodge info');
      console.log(this.lodgeInfo[0]);
    }*/
    console.log('setting emergency meeting info');
    //console.log(this.editInfo.lodge.email);
  };

  setLocationInfo = async (edit) => {
    console.log(edit);
    this.lodgeInput.name = '';//edit.suggested_edit.true_lodge_name;
    this.lodgeInput.number = '';//edit.suggested_edit.true_lodge_name;
    this.lodgeInput.lodge_id = 0;//edit.lodge.lodge_id
    this.editInfo = edit;
    console.log('setting location info');
  };

  clearLodgeInfo = () => {
    this.lodgeInfo = [];
    this.lodgeMeetings = [];
    this.lodgeMeetingsTwo = [];
    console.log('clearing lodge info');
  };

}

export const contributeDetailsStore = new ContributeDetailsStore();
(window as any).contributeDetailsStore = contributeDetailsStore;
