// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { observer, inject } from 'mobx-react';
import { FeedWallRightPanelStore } from '../../stores/feed-wall-right-panel-store';
import { FeedWallStore } from '../../stores/feed-wall-store';

import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';

import { PolicyholderStore } from '../../stores/policyholder-store';

export const SponsoredPost: React.SFC = () => {
  return (
    <div className="readMoreContent">
      <p>
        <h2>Sponsored Posts</h2>
        <p className="small-font">
          Elevate your brand's presence with Sponsored Posts! Craft compelling content
          that captivates and resonates with your audience, then let us do the rest.
          Sponsored Posts seamlessly blend into users' feeds, delivering your
          message in an authentic and engaging way. Collaborate with us to create
          content that speaks directly to your target demographic, ensuring maximum
          impact and relevance. Whether it's driving brand awareness, generating
          leads, or sparking conversation, Sponsored Posts are your secret weapon
          for standing out in the digital crowd. Get ready to make waves and
          unlock new possibilities for your brand with Sponsored Posts. Let's
          elevate your brand together!
        </p>
      </p>
    </div>
  );
};

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  feedWallRightPanelStore?: FeedWallRightPanelStore;
  feedWallStore?: FeedWallStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
  sponsoredPostOpen: boolean;
}

@inject('feedWallRightPanelStore', 'contributeStore', 'loginStore', 'feedWallStore')
@observer
export class FeedWallRightPanel extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
      sponsoredPostOpen: false
    };
  }

  reDir = (website) => {
    window.open(website, '_blank');
  }

  render() {
    if (!this.props.feedWallRightPanelStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const feedWallRightPanelStore = this.props.feedWallRightPanelStore;
    const feedWallStore = this.props.feedWallStore;

    // this works to keep the sponsored floating at the top of the screen as you scroll
    // but if you navigate to another page it causes an element null error
    // due to the other "pages" not having this element
    // because this listener is added to the window, and not (can not be) removed
    /*window.addEventListener('scroll', function() {
      var scrollPosition = window.scrollY;

      if (scrollPosition <= 80) {
        document.getElementById('fixed-element').style.top = 'inherit';
        document.getElementById('stuck-element').style.display = 'inherit';
      }
      else {
        document.getElementById('fixed-element').style.top = '0px';
        document.getElementById('stuck-element').style.display = 'none';
      }
    });*/

    console.log("!@#!@#!@#!@#!@#!@!!");
    console.log(feedWallStore.userFeedSponsoredNoticesRightPanel);
    console.log("#@!#!@#@!#!@#!@#!@#");

    return (
      <div>
        {/*<div id="stuck-element" className='feed-wall-right-panel-standard'>
        <Row>
          <Col sm="12">
            <Card className="quote-option-active small-font">
            <CardBody>
            <Row>
              <Col sm="12">
              Freemason Travellers Guide (FTG)
              </Col>
            </Row>
            <Row>
              <Col sm="12">
              ...
              </Col>
            </Row>
            </CardBody>
            </Card>
          </Col>
        </Row>
        </div>*/}
        <div id="fixed-element" className='feed-wall-right-panel-fixed sponsored-post-step'>
        <Row>
          <Col>
            <b>Sponsored</b>
          </Col>
        </Row>
        {feedWallStore.userFeedSponsoredNoticesRightPanel.length > 0 ? (
          <div>
          {feedWallStore.userFeedSponsoredNoticesRightPanel.map((notice, index) => (

            <Card className="leader-board-rp small-font" >
            <div className="pointing-cursor promoted-sponsored-info-button" onClick={() => this.setState({ sponsoredPostOpen: true })}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Information-Circle--Streamline-Core" height="14" width="14"><desc>Information Circle Streamline Icon: https://streamlinehq.com</desc><g id="information-circle--information-frame-info-more-help-point-circle"><path id="Ellipse 644" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M8 4a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Zm-3.125 6c0 -0.345 0.28 -0.625 0.625 -0.625h0.875v-2.25H6a0.625 0.625 0 1 1 0 -1.25h1c0.345 0 0.625 0.28 0.625 0.625v2.875H8.5a0.625 0.625 0 1 1 0 1.25h-3A0.625 0.625 0 0 1 4.875 10Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
            </div>
            <CardBody className="card-padding-override" onClick={async () => {
                let res = await feedWallRightPanelStore.sponsoredPostImpression(loginStore.miniToken, notice.id);
                if(res) {
                  this.reDir(notice.website);
                }
            }}>
            <Row>
              <Col className="sponsored-post-text-wrap">
                <strong>
                  {notice.title}
                </strong>
                <br/>
                <span>
                  {notice.website.replace('http://','').replace('https://','').replace('www.','')}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="sponsored-post-image-rp">
                <img className='feed-wall-image-rp-sponsored' src={process.env.REACT_APP_API_URL + notice.image}/>
              </Col>
            </Row>
            </CardBody>
            </Card>
          ))}
          </div>
          )
          :
          <div>
            <Card className="leader-board-rp small-font">
              <CardBody>
                <Row>
                  <Col>
                  Freemason Travellers Guide
                  </Col>
                  <div className="pointing-cursor promoted-sponsored-info-button" onClick={() => this.setState({ sponsoredPostOpen: true })}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" id="Information-Circle--Streamline-Core" height="14" width="14"><desc>Information Circle Streamline Icon: https://streamlinehq.com</desc><g id="information-circle--information-frame-info-more-help-point-circle"><path id="Ellipse 644" fill="#8fbffa" d="M0 7a7 7 0 1 0 14 0A7 7 0 1 0 0 7" stroke-width="1"></path><path id="Union" fill="#2859c5" fill-rule="evenodd" d="M8 4a1 1 0 1 1 -2 0 1 1 0 0 1 2 0Zm-3.125 6c0 -0.345 0.28 -0.625 0.625 -0.625h0.875v-2.25H6a0.625 0.625 0 1 1 0 -1.25h1c0.345 0 0.625 0.28 0.625 0.625v2.875H8.5a0.625 0.625 0 1 1 0 1.25h-3A0.625 0.625 0 0 1 4.875 10Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
                  </div>
                </Row>
                <Row>
                  <Col>
                  ...
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        }
        </div>
        <Modal isOpen={this.state.sponsoredPostOpen}>
          <ModalHeader
            toggle={() => this.setState({ sponsoredPostOpen: false })}
          ></ModalHeader>
          <ModalBody>
            <SponsoredPost />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.setState({ sponsoredPostOpen: false })}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default FeedWallRightPanel;
