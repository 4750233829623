// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { RaffleEntrantsStore } from '../../stores/raffle-entrants-store';
import { RaffleDrawStore } from '../../stores/raffle-draw-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { SearchableSelectInput } from '../inputs/searchable-select'


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  addLodgeStep: () => void;
  raffleDrawStep: () => void;
  raffleEntrantsStore?: RaffleEntrantsStore;
  raffleDrawStore?: RaffleDrawStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('raffleEntrantsStore', 'raffleDrawStore', 'contributeStore', 'loginStore',)
@observer
export class RaffleEntrants extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  render() {
    if (!this.props.raffleEntrantsStore) {
      console.log("ASDFASDFASDFSD");
      return null;
    }

    const loginStore = this.props.loginStore;
    const raffleEntrantsStore = this.props.raffleEntrantsStore;
    const raffleDrawStore = this.props.raffleDrawStore;
    const validationResult = raffleEntrantsStore.validationResult;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Raffle Entrants</h1>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
          <SearchableSelectInput
            id='entrant'
            label='Entrant'
            invalid={!!validationResult.entrant}
            validationMessage={validationResult.entrant}
            value={raffleEntrantsStore.entrant}
            onChange={(selectedOption) => {
              if (selectedOption != null) {
                raffleEntrantsStore.entrant = selectedOption.label;//.value
              }
            }}
            options={raffleEntrantsStore.users}
          />
          </Col>
          <Col>
            <Button color="primary" onClick={async () => {
              raffleEntrantsStore.addEntrant();
              raffleEntrantsStore.entrant = '';
            }}>
              Enter
            </Button>
          </Col>
        </Row>

        <hr/>

        <div>
        {raffleEntrantsStore.entrants.map((entrant, index) => (
          <Card className='quote-option-active' onClick={async () => {
              console.log('meh');
              console.log('index: ' + index);
            }}
            >
          <CardBody>
            {/*entrant.label} {entrant.value*/}
            {entrant}
          </CardBody>
          </Card>
        ))}
        </div>

        <hr/>

        <br/>
        <Button color="primary" onClick={async () => {
          raffleDrawStore.setEntrants(raffleEntrantsStore.entrants);
          this.props.raffleDrawStep();
        }}>
          Begin the Draw
        </Button>

      </div>
    );
  }
}

export default RaffleEntrants;
