// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';
import { ImpromptuMeetingSetupLocationStore } from '../../stores/impromptu-meeting-setup-location-store';
import { ImpromptuMeetingSetupStore } from '../../stores/impromptu-meeting-setup-store';
import { ContributeStore } from '../../stores/contribute-store';

import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import { TextArea } from '../inputs/text';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  raffleNameStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  impromptuMeetingSetupLocationStore?: ImpromptuMeetingSetupLocationStore;
  impromptuMeetingSetupStore?: ImpromptuMeetingSetupStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('impromptuMeetingSetupLocationStore', 'impromptuMeetingSetupStore', 'contributeStore', 'loginStore')
@observer
export class ImpromptuMeetingSetupLocation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  addDurationToStartDT = (date, time, duration) => {
    let new_date_time = '';

    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    // Creating a Date object with the parsed components
    const specificDate = new Date(year, month - 1, day, hours, minutes);
    //console.log("Specific date/time:", specificDate);

    // Adding minutes to the specific date/time
    const minutesToAdd = duration;
    specificDate.setMinutes(specificDate.getMinutes() + minutesToAdd);
    //console.log("Specific date/time:", specificDate);

    new_date_time = specificDate.getFullYear() +
      '-' +
      String(specificDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(specificDate.getDate()).padStart(2, '0') +
      ' ' +
      String(specificDate.getHours()).padStart(2, '0') +
      ':' +
      String(specificDate.getMinutes()).padStart(2, '0');

    return new_date_time;
  };

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  };

  render() {
    if (!this.props.impromptuMeetingSetupLocationStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const impromptuMeetingSetupLocationStore = this.props.impromptuMeetingSetupLocationStore;
    const impromptuMeetingSetupStore = this.props.impromptuMeetingSetupStore;

    const validationResult = impromptuMeetingSetupLocationStore.validationResult;

    return (
      <div>
      <Row>
        <Col sm="12">
          <h4><u>Setup An Event - Location</u></h4>
        </Col>
      </Row>

        {/* Scheduled Meeting only available to level 3 and up */}
        {loginStore.level >= 0 ? (
        <Card className='post-option-active'>
        <CardBody>
          <p>Specify the address for the event.</p>

          <div>

            <div>
            <Row>
              <Col>
                <TextInput
                  id="latitude"
                  label="Latitude"
                  placeholder="Enter a latitude for the address"
                  readonly={!!impromptuMeetingSetupStore.isImmediate}
                  value={impromptuMeetingSetupLocationStore.latitude}
                  onChange={e =>
                    (impromptuMeetingSetupLocationStore.latitude = e.target.value)
                  }
                  invalid={!!validationResult.latitude}
                  validationMessage={validationResult.latitude}
                />
              </Col>
              <Col>
                <TextInput
                  id="longitude"
                  label="Longitude"
                  placeholder="Enter a longitude for the address"
                  readonly={!!impromptuMeetingSetupStore.isImmediate}
                  value={impromptuMeetingSetupLocationStore.longitude}
                  onChange={e =>
                    (impromptuMeetingSetupLocationStore.longitude = e.target.value)
                  }
                  invalid={!!validationResult.longitude}
                  validationMessage={validationResult.longitude}
                />
              </Col>
            </Row>
            {
              impromptuMeetingSetupStore.isImmediate ?
                <a href={`http://maps.google.com/maps?q=${
                impromptuMeetingSetupLocationStore.link !== ''
                  ? impromptuMeetingSetupLocationStore.link
                  : ''
                }`} target="_blank">You are here</a>
                : ''
            }
            <Row>
              <Col>
                <TextInput
                  id="address"
                  label="Address"
                  placeholder="Enter address"
                  value={impromptuMeetingSetupLocationStore.address}
                  onChange={e =>
                    (impromptuMeetingSetupLocationStore.address = e.target.value)
                  }
                  invalid={!!validationResult.address}
                  validationMessage={validationResult.address}
                />
              </Col>
            </Row>
            </div>

          </div>

          </CardBody>
          </Card>
        ) : 'You need to reach at least level 3 to create a scheduled event. Your current level doesn\'t meet this requirement.'
        }


        <br/>
        <Button color="primary" onClick={this.props.nextStep}>
          Next
        </Button>
        <Button color="primary" onClick={this.props.previousStep}>
          Back
        </Button>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default ImpromptuMeetingSetupLocation;
