import { observable, action } from 'mobx';
import { SuggestEditMeeting } from '../domain/suggest-edit-meeting';
import api from '../api';

export class SuggestEditMeetingStore {
  @observable isLoading = false;

  @observable lodgeInput: Partial<SuggestEditMeeting> = {
    country: '',
    district: '',
    name: '',
    number: '',
    constitution: '',
    degree: '',
    meetings: [{
      time: '',
      position: '',
      day: '',
      month: '',
      type: '',
      description: '',
      description_extra: ''
    }]
  };
  @observable validationResult: { [k: string]: string } = {};

  fieldsToFill: string[] = [];
  
  constructor() {
    this.validationResult = {};
    this.fieldsToFill = [];
  }
  
  validate = () => {
    const request = this.lodgeInput;
    //console.log("1 ppp");
    //console.log(request.meetings);
    //console.log("2 ppp");
    this.validationResult = { };
    this.fieldsToFill = [];

    let meetings_cnt = 0;
    let installation_cnt = 0;
    request.meetings.forEach((meeting, index) => {
      //console.log("3 ppp");
      //console.log(meeting);
      //console.log(index);
      //console.log("4 ppp");
      if (meeting.type != '3')
      {
        meetings_cnt += 1;
        if (meeting.type == '2') {
          installation_cnt += 1;
        }
        const meetingKey = `meeting_${index + 1}`;
        this.validationResult[`${meetingKey}.type`] = (meeting.type == '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.time`] = (meeting.time == '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.position`] = (meeting.position == '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.day`] = (meeting.day == '') ? '* Required' : '';
        this.validationResult[`${meetingKey}.month`] = (meeting.month == '') ? '* Required' : '';
      }
    });

    //console.log("meetings_cnt");
    //console.log(meetings_cnt);
    //console.log("installation_cnt");
    //console.log(installation_cnt);
    if (meetings_cnt < 2 || (installation_cnt > 1 || installation_cnt == 0)) {
      this.validationResult['enough_meetings'] = '* Minimum two meetings, one of which must be an Installation!'
    }

    //this.validationResult.meetings[0].type = '* Required';
    this.fieldsToFill = Object.keys(this.validationResult)

    console.log("this.validationResult");
    console.log(this.validationResult);
    console.log("this.fieldsToFill");
    console.log(this.fieldsToFill);
  };

  checkValidation = () => {
    if(this.hasValidationError())
    {
      return false;
    }
    else
    {
      return true;
    }
  };

  hasValidationError = (): boolean => {
    return !!Object.entries(this.validationResult).find(
      ([_, value]) => !!value
    );
  };

  @action
  addMeeting = () => {
      this.lodgeInput.meetings.push({
        time: '',
        position: '',
        day: '',
        month: '',
        type: '',
        description: '',
        description_extra: ''
      });
  };

  @action
  removeMeeting = (indexToRemove) => {
    if (indexToRemove >= 0 && indexToRemove < this.lodgeInput.meetings.length) {
      this.lodgeInput.meetings.splice(indexToRemove, 1);
    }
  };

  setSuggestEditMeetingData = (meetingData: any) => {
    if (meetingData.length > 0) {
      this.lodgeInput.meetings = meetingData;
    }
  };

  clearMeetingInput = () => {
    this.lodgeInput.country = '';
    this.lodgeInput.district = '';
    this.lodgeInput.name = '';
    this.lodgeInput.number = '';
    this.lodgeInput.constitution = '';
    this.lodgeInput.degree = '';
    this.lodgeInput.meetings = [];
    /*this.lodgeInput.meetings.push({
      time: '',
      position: '',
      day: '',
      month: '',
      type: ''
    });*/
    console.log('clearing edit meeting input');
  };

}

export const suggestEditMeetingStore = new SuggestEditMeetingStore();
(window as any).suggestEditMeetingStore = suggestEditMeetingStore;
