// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import Tree from 'react-d3-tree';

import { ToastContainer, toast } from 'react-toastify';

import { observer, inject } from 'mobx-react';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { DiagramStore } from '../../stores/diagram-store';

import { LodgeDetailsStore } from '../../stores/lodge-details-store';

import { ThankYouStore } from '../../stores/thank-you-store';

import { LoginStore } from '../../stores/login-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';

import { PolicyholderStore } from '../../stores/policyholder-store';

export const contactTypes = [
  {
    value: '1',
    label: 'Enquiry'
  },
  {
    value: '2',
    label: 'Visit'
  }
];



interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  diagramStore?: DiagramStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('diagramStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore')
@observer
export class Diagram extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.diagramStore) {
      return;
    }

    const diagramStore = this.props.diagramStore;
    diagramStore.getDiagramData();
    console.log("------- diagramStore.dataResults -------");
    console.log(diagramStore.dataResults);
    console.log("---------------------------------------");

    const contactInput = diagramStore.contactInput;
    const validationResult = diagramStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;


    const straightPathFunc = (linkDatum, orientation) => {
      const { source, target } = linkDatum;
      return orientation === 'horizontal'
        ? `M${source.y},${source.x}L${target.y},${target.x}`
        : `M${source.x},${source.y}L${target.x},${target.y}`;
    };

    // This is a simplified example of an org chart with a depth of 2.
    // Note how deeper levels are defined recursively via the `children` property.
    const orgChart1 = {
      name: 'CEO',
      attributes: {
        department: 'Everything',
      },
      children: [
        {
          name: 'Manager',
          attributes: {
            department: 'Production',
          },
          children: [
            {
              name: 'Foreman',
              attributes: {
                department: 'Fabrication',
              },
              children: [
                {
                  name: 'Worker',
                  attributes: {
                    department: 'Springs',
                  },
                },
              ],
            },
            {
              name: 'Foreman',
              attributes: {
                department: 'Assembly',
              },
              children: [
                {
                  name: 'Worker',
                },
              ],
            },
          ],
        },
      ],
    };

    let orgChart = orgChart1;

    if (!diagramStore.isLoading) {
      orgChart = diagramStore.dataResults;
    }



    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Diagram</h1>
          </Col>
        </Row>
        <br />
        <Row>
        <Col>
        <div id="treeWrapper" className="diagram">
          <Tree
            data={orgChart}
            orientation="vertical"
          />
        </div>
        </Col>
        </Row>

        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default Diagram;
