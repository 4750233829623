import { observable, action } from 'mobx';
import { FeedWallRightPanel } from '../domain/feed-wall-right-panel';
import api from '../api';

export class FeedWallRightPanelStore {
  @observable isLoading = false;

  @observable typeOfThankYou = '';

  @observable editResults: any = [];

  constructor() {}

  sponsoredPostImpression = async (miniToken, id) => {
    let dat = {
      miniToken: miniToken,
      sponsoredPost: id
    };

    let result = [];
    try {
      result = await api.sponsoredPostImpression(dat);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      console.log('======= sponsoredPostImpression =======');
      //this.userFeedSponsoredNotices = result['sponsoredNotices'];
      //this.userFeedSponsoredNoticesRightPanel = result['sponsoredNoticesRightPanel'];
      //this.userFeedUpcomingMeetings = result['upcomingMeetings'];
      //this.userFeedTopThreeOnLeaderboard = result['topThreeOnLeaderboard'];
      console.log(result);
      console.log('============================');
      //this.isLoading = false;
    }
  };

}

export const feedWallRightPanelStore = new FeedWallRightPanelStore();
(window as any).feedWallRightPanelStore = feedWallRightPanelStore;
