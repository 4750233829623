// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';
import { observer, inject } from 'mobx-react';

import { ToastContainer, toast } from 'react-toastify';

import { ImpromptuMeetingSetupStore } from '../../stores/impromptu-meeting-setup-store';
import { ImpromptuMeetingSetupLocationStore } from '../../stores/impromptu-meeting-setup-location-store';
import { ImpromptuMeetingSetupImageStore } from '../../stores/impromptu-meeting-setup-image-store';
import { ImpromptuMeetingSetupConfirmationStore } from '../../stores/impromptu-meeting-setup-confirmation-store';
import { ContributeStore } from '../../stores/contribute-store';
import { LodgeStore } from '../../stores/lodge-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { AddMeetingStore } from '../../stores/add-meeting-store';
import { AddContactStore } from '../../stores/add-contact-store';
import { LoginStore } from '../../stores/login-store';
import { ThankYouStore } from '../../stores/thank-you-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput } from '../inputs/text';
import { TextArea } from '../inputs/text';

import { PolicyholderStore } from '../../stores/policyholder-store';


interface Props {
  previousStep: () => void;
  nextStep: () => void;
  searchStep: () => void;
  contributeStep: () => void;
  raffleNameStep: () => void;
  thankYouStep: () => void;
  addLodgeStep: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  impromptuMeetingSetupStore?: ImpromptuMeetingSetupStore;
  impromptuMeetingSetupLocationStore?: ImpromptuMeetingSetupLocationStore;
  impromptuMeetingSetupImageStore?: ImpromptuMeetingSetupImageStore;
  impromptuMeetingSetupConfirmationStore?: ImpromptuMeetingSetupConfirmationStore;
  contributeStore?: ContributeStore;
  loginStore?: LoginStore;
  thankYouStore?: ThankYouStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject(
  'impromptuMeetingSetupStore',
  'impromptuMeetingSetupLocationStore',
  'impromptuMeetingSetupImageStore',
  'impromptuMeetingSetupConfirmationStore',
  'contributeStore',
  'loginStore',
  'thankYouStore'
)
@observer
export class ImpromptuMeetingSetupConfirmation extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false,
    };
  }

  addDurationToStartDT = (date, time, duration) => {
    let new_date_time = '';

    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes] = time.split(':').map(Number);

    // Creating a Date object with the parsed components
    const specificDate = new Date(year, month - 1, day, hours, minutes);
    //console.log("Specific date/time:", specificDate);

    // Adding minutes to the specific date/time
    const minutesToAdd = duration;
    specificDate.setMinutes(specificDate.getMinutes() + minutesToAdd);
    //console.log("Specific date/time:", specificDate);

    new_date_time = specificDate.getFullYear() +
      '-' +
      String(specificDate.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(specificDate.getDate()).padStart(2, '0') +
      ' ' +
      String(specificDate.getHours()).padStart(2, '0') +
      ':' +
      String(specificDate.getMinutes()).padStart(2, '0');

    return new_date_time;
  };

  checkIfToday = (the_date) => {
    // Create a new Date object for the given date
    const givenDate = new Date(the_date); // Replace this with your desired date

    // Get the current date
    const currentDate = new Date();

    // Compare year, month, and day components
    const isToday = (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );

    return isToday;
  };

  render() {
    if (!this.props.impromptuMeetingSetupConfirmationStore) {
      return;
    }

    const loginStore = this.props.loginStore;
    const impromptuMeetingSetupStore = this.props.impromptuMeetingSetupStore;
    const impromptuMeetingSetupLocationStore = this.props.impromptuMeetingSetupLocationStore;
    const impromptuMeetingSetupImageStore = this.props.impromptuMeetingSetupImageStore;
    const impromptuMeetingSetupConfirmationStore = this.props.impromptuMeetingSetupConfirmationStore;

    const validationResult = impromptuMeetingSetupConfirmationStore.validationResult;

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'contribution';

    return (
      <div>
      <Row>
        <Col sm="12">
          <h4><u>Setup An Event - Confirmation</u></h4>
        </Col>
      </Row>


      <Card className='post-option-active'>
      <CardBody>
        {impromptuMeetingSetupStore.isScheduled
          ?
          <p>This is a scheduled event.</p>
          :
          <p>This is an immediate event.</p>
        }


        <div>
        <Card className='post-option-active'>
          <CardBody>
            <b>{impromptuMeetingSetupStore.title}</b>
            <hr/>
            <span className="small-font">
            Date: {impromptuMeetingSetupStore.date}
            </span>
            <br/>
            <span className="small-font">
            Time: {impromptuMeetingSetupStore.time}
            </span>
            <br/>
            <br/>
            <div className="pre pre-code">{impromptuMeetingSetupStore.description}</div>
            <span className="small-font">
            <i>Location: <a href={`http://maps.google.com/maps?q=${impromptuMeetingSetupLocationStore.latitude},${impromptuMeetingSetupLocationStore.longitude}`} target="_blank">{impromptuMeetingSetupLocationStore.address}</a></i>
            </span>
            <Row>
              <Col sm="12" className="post-restricted-height">
                <img className='feed-wall-image' src={impromptuMeetingSetupImageStore.image_file_data}/>
              </Col>
            </Row>
            <br/>
            <br/>
            <span className="small-font">
            <i>Contact: {loginStore.username}</i>
            <br/>
            <i>Tel: <a href={`tel:${loginStore.contact_number}`} target="_blank">{loginStore.contact_number}</a></i>
            <br/>
            <i>Email: <a href={`mailto:${loginStore.email}`} target="_blank">{loginStore.email}</a></i>
            </span>
          </CardBody>
        </Card>
        </div>

        </CardBody>
        </Card>


        <br/>
        <Button color="primary" onClick={async () => {
          this.props.setLoadingTrue();

          const res = await impromptuMeetingSetupConfirmationStore.makeSuggestion(
            impromptuMeetingSetupStore,
            impromptuMeetingSetupLocationStore,
            impromptuMeetingSetupImageStore,
            loginStore);
          /*const success = addConfirmationStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {*/
            //this.props.nextStep()
          //}
          console.log('---------');
          console.log(res);
          console.log('---------');
          if (res) {
            this.props.thankYouStep();
          }
          else {
            toast.warning("Could not make suggestion!");
          }

          this.props.setLoadingFalse();
        }}>
          Submit
        </Button>
        <Button color="primary" onClick={this.props.previousStep}>
          Back
        </Button>
        <hr />
        <br />
        <div id='navigation_buttons'>
          <Button color="primary" onClick={this.props.searchStep}>
            Search
          </Button>
          {loginStore.isLoggedIn ? (
          <Button color="primary" onClick={async () => {
            this.props.contributeStore.setEdits(loginStore);
            this.props.contributeStep();
          }}>
            Contribute
          </Button>
          ): ''}
        </div>
      </div>
    );
  }
}

export default ImpromptuMeetingSetupConfirmation;
