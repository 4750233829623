import { observable, action } from 'mobx';
import { Diagram } from '../domain/diagram';
import api from '../api';

export class DiagramStore {
  @observable isLoading = false;

  @observable contactInput: Partial<Diagram> = {
    message: '',
    type: '',
    dining: false,
    lodge_id: 0
  };
  @observable validationResult: { [k: string]: string } = {};

  @observable dataResults: any = [];

  constructor() {}

  getDiagramData = async () => {
    this.isLoading = true;
    try {
      const result = await api.getDiagramData([]);
      console.log('======= getDiagramData =======');
      this.dataResults = result;
      console.log(result);
      console.log('============================');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.isLoading = false;
    }
  };
}

export const diagramStore = new DiagramStore();
(window as any).diagramStore = diagramStore;
