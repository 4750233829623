import { observable, action } from 'mobx';
import { PolicyDocs } from '../domain/policy-docs';
import api from '../api';

export class PolicyDocsStore {

  @observable termsModalOpen: boolean = false;
  @observable termsModalError: boolean = false;
  @observable termsModalSuccess: boolean = false;

  @observable privacyModalOpen: boolean = false;
  @observable privacyModalError: boolean = false;
  @observable privacyModalSuccess: boolean = false;

  constructor() {}

  toggleTermsModal = () => {
    this.termsModalOpen = !this.termsModalOpen;
    this.termsModalError = false;
    this.termsModalSuccess = false;
  };

  togglePrivacyModal = () => {
    this.privacyModalOpen = !this.privacyModalOpen;
    this.privacyModalError = false;
    this.privacyModalSuccess = false;
  };
}

export const policyDocsStore = new PolicyDocsStore();
(window as any).policyDocsStore = policyDocsStore;
