// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

import { observer, inject } from 'mobx-react';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { ContactStore } from '../../stores/contact-store';
import { LodgeDetailsStore } from '../../stores/lodge-details-store';
import { ThankYouStore } from '../../stores/thank-you-store';
import { LoginStore } from '../../stores/login-store';

import { SelectInput } from '../inputs/select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';

export const contactTypes = [
  {
    value: '1',
    label: 'Enquiry'
  },
  {
    value: '2',
    label: 'Visit'
  }
];

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  contactStore?: ContactStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('contactStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore')
@observer
export class Contact extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.contactStore) {
      return;
    }

    const contactStore = this.props.contactStore;
    const contactInput = contactStore.contactInput;
    const validationResult = contactStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;

    return (
      <div>
      <Card>
      <CardBody>
        <Row>
          <Col sm="12">
            <h1>Contact Lodge</h1>
          </Col>
        </Row>
        <br />

          <Row>
            <Col sm="6">
              <SelectInput
                id='type'
                label='Type'
                invalid={!!validationResult.type}
                validationMessage={validationResult.type}
                value={contactInput.type}
                onChange={e => {
                  (contactInput.type = e.target.value)
                }}
                options={contactTypes}
              />
            </Col>
          </Row>
          <Row>
            {contactInput.type == '2' ? (
              <Col sm="6">
                <FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type='checkbox'
                        name='dining'
                        checked={contactInput.dining}
                        onClick={() => {
                          contactInput.dining = !contactInput.dining;
                        }}
                        />{' '}
                        Will you be dining?
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            ): ''}

          </Row>

        <Row>
          <Col>
            <TextArea
              id="message"
              label="Message"
              value={contactInput.message}
              onChange={e =>
                (contactInput.message = e.target.value)
              }
              invalid={!!validationResult.message}
              validationMessage={validationResult.message}
            />
          </Col>
        </Row>

        <hr/>
        
        <Button color="" onClick={async () => {
          if (await contactStore.makeContact(
                (lodgeDetailsStore.lodgeInfo && lodgeDetailsStore.lodgeInfo[0] && lodgeDetailsStore.lodgeInfo[0].id !== undefined) ? lodgeDetailsStore.lodgeInfo[0].id : undefined,
                contactInput.message,
                contactInput.type,
                contactInput.dining,
                loginStore
              )) {
              toast.success("Your message has been sent!");
              this.props.thankYouStep();
          }
          else {
            toast.warning("Could not send message!");
          }
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Mail-Send-Email-Message--Streamline-Core"><desc>Mail Send Email Message Streamline Icon: https://streamlinehq.com</desc><g id="mail-send-email-message--send-email-paper-airplane-deliver"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M13.967 0.68a0.5 0.5 0 0 0 -0.647 -0.647l-13 5a0.5 0.5 0 0 0 -0.027 0.922l5.33 2.423 2.422 5.329a0.5 0.5 0 0 0 0.922 -0.027l5 -13Z" clip-rule="evenodd" stroke-width="1"></path><path id="Intersect" fill="#2859c5" fill-rule="evenodd" d="M5.954 9.107 9.03 6.03a0.75 0.75 0 0 0 -1.06 -1.06L4.893 8.046l0.73 0.332 0.33 0.729Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Send
        </Button>
        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>

        </CardBody>
        </Card>
      </div>
    );
  }
}

export default Contact;
