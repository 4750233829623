// @ts-check
import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  FormGroup
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

import { observer, inject } from 'mobx-react';
import { AddLodgeStore } from '../../stores/add-lodge-store';
import { EmergencyMeetingStore } from '../../stores/emergency-meeting-store';

import { LodgeDetailsStore } from '../../stores/lodge-details-store';

import { ThankYouStore } from '../../stores/thank-you-store';

import { LoginStore } from '../../stores/login-store';

import { ConfigurationStore } from '../../stores/configuration-store';

import { QuoteStore } from '../../stores/quote-store';
import { CurrencyInput } from '../inputs/currency';
import { DateInput } from '../inputs/date';
import { TimeInput } from '../inputs/time';
import { SelectInput } from '../inputs/select';
import { SearchableSelectInput } from '../inputs/searchable-select';
import { NumberInput } from '../inputs/number';
import { TextInput, TextArea } from '../inputs/text';

import { PolicyholderStore } from '../../stores/policyholder-store';

interface Props {
  previousStep: () => void;
  nextStep: () => void;
  thankYouStep: () => void;
  lodgeDetailsStep: () => void;
  emergencyMeetingStore?: EmergencyMeetingStore;
  lodgeDetailsStore?: LodgeDetailsStore;
  thankYouStore?: ThankYouStore;
  loginStore?: LoginStore;
  configurationStore?: ConfigurationStore;
}

interface State {
  clicked: boolean;
  valid: any; // change this
}

@inject('emergencyMeetingStore', 'lodgeDetailsStore', 'thankYouStore', 'loginStore', 'configurationStore')
@observer
export class EmergencyMeeting extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      valid: {},
      clicked: false
    };
  }

  render() {
    if (!this.props.emergencyMeetingStore) {
      return;
    }

    const membershipClaimStore = this.props.emergencyMeetingStore;
    //console.log("------- membershipClaimStore.lodgeResults -------");
    //console.log(membershipClaimStore.lodgeResults);
    //console.log("---------------------------------------");

    const membershipClaimInput = membershipClaimStore.membershipClaimInput;
    const validationResult = membershipClaimStore.validationResult;

    const lodgeDetailsStore = this.props.lodgeDetailsStore;//lodgeInput.lodge_id
    //console.log("------- lodgeDetailsStore.lodgeInput -------");
    //console.log(lodgeDetailsStore.lodgeInput);
    //console.log("---------------------------------------");

    const configurationStore = this.props.configurationStore;
    //console.log("------- configurationStore.addressResults -------");
    //console.log(configurationStore.addressResults);
    //console.log("---------------------------------------");

    const thankYouStore = this.props.thankYouStore;
    thankYouStore.typeOfThankYou = 'message';

    const loginStore = this.props.loginStore;

    return (
      <div>
        <Row>
          <Col sm="12">
            <h1>Emergency Meeting</h1>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="3">
          <DateInput
              label="Date of Meeting*"
              invalid={!!validationResult.date}
              validationMessage={validationResult.date}
              value={membershipClaimInput.date}
              onChange={e => {
                membershipClaimInput.date = e.target.value;
              }}
              id="date"
            />
          </Col>
          <Col sm="3">
            <TimeInput
                label="Time of Meeting*"
                invalid={!!validationResult.time}
                validationMessage={validationResult.time}
                value={membershipClaimInput.time}
                onChange={e => {
                  membershipClaimInput.time = e.target.value;
                }}
                id="time"
              />
          </Col>
          <Col sm="6">
            <SelectInput
              id='description'
              label='Description'
              invalid={!!validationResult['description']}
              validationMessage={validationResult['description']}
              value={membershipClaimInput.description}
              onChange={e => {
                membershipClaimStore.membershipClaimInput.description = e.target.value;
              }}
              options={configurationStore.filteredDescriptions}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchableSelectInput
              id='address'
              label='Address'
              invalid={!!validationResult.address}
              validationMessage={validationResult.address}
              value={membershipClaimInput.address}
              onChange={(selectedOption) => {
                if (selectedOption != null) {
                  membershipClaimStore.membershipClaimInput.address = selectedOption.value;
                }
              }}
              options={configurationStore.filteredAddresses}
            />
          </Col>
        </Row>


        <br/>
        <Button color="primary" onClick={async () => {
          //toast.info("TEST!");

          membershipClaimStore.validate();
          const success = membershipClaimStore.checkValidation();
          console.log('----');
          console.log(success);
          console.log('----');
          if (success) {
            if (await membershipClaimStore.addEmergencyMeeting(
                  lodgeDetailsStore.lodgeInfo[0].id,
                  membershipClaimInput.date,
                  membershipClaimInput.time,
                  membershipClaimInput.description,
                  membershipClaimInput.address,
                  loginStore
                )) {
                toast.success("The emergency meeting has been proposed!");
                this.props.thankYouStep();
            }
            else {
              toast.warning("Could not log the emergency meeting!");
            }
          }
        }}>
          Send
        </Button>
        <Button color="" onClick={this.props.lodgeDetailsStep}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="14" width="14" id="Return-2--Streamline-Core"><desc>Return 2 Streamline Icon: https://streamlinehq.com</desc><g id="return-2--arrow-return-enter-keyboard"><path id="Union" fill="#8fbffa" fill-rule="evenodd" d="M6.545 0.998a1 1 0 0 0 0 2h2.728a2.638 2.638 0 0 1 0 5.275H4.817V6.545a1 1 0 0 0 -1.707 -0.707L0.384 8.564a1.004 1.004 0 0 0 -0.22 1.09c0.049 0.119 0.121 0.23 0.218 0.327l2.728 2.728a1 1 0 0 0 1.707 -0.707v-1.729h4.456a4.638 4.638 0 1 0 0 -9.275H6.545Z" clip-rule="evenodd" stroke-width="1"></path></g></svg>
          Back
        </Button>
      </div>
    );
  }
}

export default EmergencyMeeting;
